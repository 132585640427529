.header-md::after {
	background-image: none;
}

ion-toolbar {
	--border-color: transparent !important;
}

ion-toolbar.dynamic-header {
	--min-height: var(--ion-toolbar-min-height);
	--ion-toolbar-background: transparent;
	--ion-toolbar-color: var(--ion-color-dark);
	margin-top: -15px;
} 

.web ion-toolbar.dynamic-header {
	margin-top: 0;
	position: absolute;
	top: 80px;
	z-index: 6;
	pointer-events: none;
}

.web ion-toolbar.dynamic-header ion-buttons {
	pointer-events: all;
}

#main > ion-content {
	z-index: 5;
}

ion-content.ion-color-transparent {
	--background: transparent;
}

.ion-page {
    min-width: 285px;
}

.icomoon {
	font-family: var(--ion-icomoon-font-family);
	font-size: 24px;
}

span > .icomoon {
	font-size: 20px;
}

.icomoon::before {
	display: block;
}

.icomoon.ico-voucher::before {
	content: '\e900';
}

.icomoon.ico-profile::before {
	content: '\e901';
}

.icomoon.ico-basket::before {
	content: '\e902';
}

.icomoon.ico-map::before {
	content: '\e903';
}

.icomoon.ico-home::before {
	content: '\e904';
}

.icomoon.ico-order::before {
	content: '\e905';
}

.icomoon.ico-social::before {
	content: '\e906';
}

.icomoon.ico-orders::before {
	content: '\e907';
}

.icomoon.ico-allergens::before {
	content: '\e908';
}

.icomoon.ico-logout::before {
	content: '\e909'
}

.icomoon.ico-feedback::before {
	content: '\e90a';
}

.icomoon.ico-radio::before {
	content: '\e90b';
}

ion-header.static {
	z-index: 5;
  }
  ion-header.static.route-dashboard {
	z-index: 6;
  }
  ion-header.static.route-loyalty {
	z-index: 6;
  }
  ion-toolbar {
	--border-color: #fff;
  }
  ion-toolbar.primary-toolbar {
	--min-height: 80px;
	--background: transparent;
  }
  /* ion-toolbar.primary-toolbar:not(.no-logo) {
	--background: url(../../assets/images/logo-main.svg) no-repeat center / auto 35%;
  } */
  
  .web ion-header:not(.route-order).static {
	position: absolute;
	right: 0;
	background: transparent none;
	min-height: var(--ion-toolbar-min-height);
	height: auto;
	margin: 0;
	z-index: 6;
	pointer-events: none;
  }
  .web ion-header.static{
	position: absolute;
  }
  .web ion-header.static ion-buttons {
	pointer-events: all;
  }
  
  .web ion-toolbar.primary-toolbar {
	--background: transparent url(/static/media/logo-main.193dd6f3.svg) no-repeat 50% 50% / auto 110%;
  }
  
  .web ion-toolbar.primary-toolbar ion-buttons:first-of-type,
  .web ion-toolbar.primary-toolbar ion-buttons:last-of-type,
  .web ion-toolbar.dynamic-header ion-buttons:first-of-type {
	background-color: rgba(255, 255, 255, 0.5);
  }
  
  .web ion-toolbar.primary-toolbar ion-buttons:last-of-type ion-button {
	--padding-top: 12px;
	--padding-end: 12px;
	--padding-bottom: 12px;
	--padding-start: 12px;
	margin-left: 0;
	margin-right: 0;
	margin-top: 0;
	margin-bottom: 0;
	width: 50px;
	height: 50px;
  }
  
  .web ion-toolbar.primary-toolbar ion-button.image-button {
	display: none;
  }
  
  ion-header.static ion-toolbar:not(.primary-toolbar) {
	--min-height: var(--ion-toolbar-min-height);
  }
  
  ion-button.solo-button {
	--padding-start: 6px;
	--padding-end: 6px;
  }
  
  ion-button.basket-button {
	height: 45px;
  }
  
  ion-button.basket-button > div > div > .badge,
  ion-button.basket-button > div > div > .badge-small {
	position: absolute;
	bottom: 0;
	width: 20px;
	height: 20px;
	right: -4px;
	z-index: 1;
	padding: 4px 1px;
	font-size: 12px;
	border-radius: 60px;
  }
  
  ion-button.basket-button > div > div > .badge-small {
	width: 18px;
	height: 18px;
	right: -2px;
	padding: 2px 0 0 0;
  }
  
  ion-button.basket-button > div > .basket-icon-wrapper {
	position: relative;
  }
  
  ion-button.basket-button > div > div > ion-icon {
	font-size: 30px;
  }
  
  /* .primary-toolbar .button {
	color: var(--ion-color-dark);
  } */
  
  .header-title .title{
	margin: 0;
}

  .image-button {
	-webkit-flex: 1 1;
	        flex: 1 1;
	background: transparent;
	--ripple-color: transparent;
	background-position: center;
	--background: url(/static/media/logo-main.193dd6f3.svg) no-repeat center / auto 50%;
  }
  
  .icon {
	--ripple-color: transparent;
  }
  .web ion-button.basket-button > div > div > ion-badge.badge-small {
	--ion-color-primary: var(--ion-color-secondary);
	color: #000;
	font-size: 9px;
	min-width: 15px;
	text-align: center;
	--padding-top: 3px;
	--padding-bottom: 3px;
	--padding-start: 3px;
	--padding-end: 3px;
	width: auto;
	height: auto;
	right: 0;
	border-radius: 8px;
	padding: 3px;
	border: 1px solid var(--ion-color-white);
  }
  
  .web ion-button.basket-button > div > div:last-child {
	--ion-color-gray: var(--ion-color-secondary);
  }
  
  .web .header-ios ion-toolbar:last-of-type,
  .web .header-md ion-toolbar:last-of-type {
	--border-width: 0 0 0;
  }
  
  .menu-button {
	cursor: pointer;
	margin-left: 20px;
	height: 16px;
  }
  
  .tertiary-text {
	color: var(--ion-color-tertiary) !important;
  }
  .header-title {
	text-align: center !important;
	margin-bottom: 0 !important;
	--background:transparent;
	width: 100%;
  }
  .web .header-title {
	display: none;
  }
  
.promo-bar, .promo-bar-scrim {
	position: absolute;
	position: fixed;
	z-index: 5;
}

/* .promo-bar-scrim {
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.000000001);
} */

.promo-bar {
	left: 10%;
	right: 10%;
	top: 20%;
	height: 40px;
	border-radius: 20px;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	padding-left: 40px;
	background: var(--ion-color-secondary) url(/static/media/info-white.d47b8957.svg) no-repeat 5px 50%;
	background-size: 30px 30px;
}

.promo-bar.warning {
	background-image: url(/static/media/warning-white.07991784.svg);
}

.promo-bar.error {
	background-image: url(/static/media/error-white.25f0fcdb.svg);
}

.promo-bar-type {
	font-weight: bold;
	text-transform: uppercase;
}

.promo-bar-type, .promo-bar-body {
	color: var(--ion-color-primary-contrast);
}

.web .promo-bar {
	max-width: var(--okx-bsmodal-width-lg);
	margin-left: auto;
	margin-right: auto;
	top: 2%;
}

@media screen and (min-height: 615px) and (max-height: 722px) {
	.web .promo-bar {
		top: 7%;
	}
}

@media screen and (min-height: 722px) {
	.web .promo-bar {
		top: 15%;
	}
}
ion-content div.dash-layout {
	background-image: url(/static/media/dashboard-03.45aec6a4.jpg);
	background-repeat: no-repeat;
	background-position: 50% 100%;
	background-size: cover;
}

ion-content div.dash-layout::before {
	content: '';
	display: block;
	position: absolute;
	left: 0; top: 0; right: 0;
	height: 60%;
	z-index: 0;
	background-image: linear-gradient(rgba(255,255,255,1) 0%, rgba(255,255,255,0) 60%);
}

ion-content div.dash-layout::after {
	content: '';
	display: block;
	position: absolute;
	left: 0; bottom: 0; right: 0;
	height: 100%;
	z-index: 0;
	background-image: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1));
}

.dash-card {
	position: absolute;
	left: 30px; right: 30px; bottom: 0;
	z-index: 1;
	--background: var(--okx-background-color);
	border-radius: var(--ion-card-radius);
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	padding-bottom: 25%;
	background-image: url(/static/media/rope-transparent.09f9e2dc.svg);
	background-repeat: no-repeat;
	background-position: 50% 100%;
	background-size: 60% auto;
	box-shadow: 0 0 32px rgba(0,0,0,0.25);
	margin-top: 0;
	margin-bottom: 0;
}

.dash-menu {
	padding: 0 64px;
}

.dash-menu .list-md {
	background: transparent;
}

.dash-menu ion-item {
	--color: var(--ion-color-primary);
	--background: trasparent;
	--background-hover: trasparent;
	--min-height: 32px;
	--border-color: var(--okx-color-border-dash); /* This firstly variable of border-color replaced with border-color right below */
	--border-color: rgba(var(--ion-color-gray-rgb), .3);
}

.dash-card ion-card-title, .dash-menu ion-item {
	font-weight: bold;
	white-space: pre-wrap;
}

.dash-card ion-card-title {
	font-size: 20px;
	text-transform: uppercase;
	letter-spacing: .033em;
}

.dash-card ion-card-title > span {
	font-size: var(--ion-font-size);
	font-weight: normal;
	text-transform: none;
	text-transform: initial;
	letter-spacing: normal;
}

.dash-menu ion-label {
	text-align: center;
	font-weight: bold;
}

.dash-menu .item ion-label.sc-ion-label-ios-h {
	font-weight: bold;
	letter-spacing: .033em;
}

.web .static.route-dashboard {
	background: transparent none;
}

.web ion-content.route-dashboard {
	--background: transparent none;
	/* --ion-color-white: transparent; */
}

.web #main > ion-content.route-dashboard div.dash-layout.absolute-content {
	background: transparent none;
}

.web #main > ion-content div.dash-layout::before {
	background-image: none;
}

.web .dash-card {
	position: absolute;
	top: 50%;
	bottom: auto;
	left: 50%;
	right: auto;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	width: 50%;
	max-width: var(--okx-bsmodal-width-md);
	min-width: 280px;
	padding-top: 24px;
	padding-bottom: 35px;
	margin-left: auto;
	margin-right: auto;
	border-radius: var(--okx-box-radius);
	background-position: 50% 108%;
	background-size: 120px auto;
}

.web .dash-menu ion-item {
	--color: var(--ion-color-secondary-contrast);
	--background: var(--ion-color-secondary);
	margin-bottom: 20px;
	border-radius: var(--okx-small-radius);
}

.reorder-button{
	float: right;
	--border-radius: 25px 0px 0px 25px;
	width: 25%;
	height: 25px;
}

.content-spinner {
    display: -webkit-flex;
    display: flex;
    /*width: 100%;
    height: 100%;*/
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 6;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
	background: rgba(0,0,0,0.3);
	border-radius: inherit;
}

.transparent {
  background: rgba(0,0,0,0);
}

.loyalty-content.scan {
  padding-bottom: 60px;
}

.loyalty-content.scan h2 {
  margin-top: 20px;
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 0.044em;
  /* margin-left: 24px; */
}

.loyalty-title-wrap {
  text-align: left;
  padding-left: 30px;
}

.noQrCode {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 20px;
}

.qr-holder {
  margin: 0 !important;
}

/* end second tab */

.points-tab {
  background: transparent url(/static/media/history-details.9bd6f769.jpg) no-repeat;
  background-position: 50% 100%;
  background-size: 100% auto;
  z-index: 1;
}

.points-tab::after {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  content: '';
  /* background-image: linear-gradient(#fff 65%, rgba(255,255,255,0) 180%); */
  background-image: linear-gradient(#fff 62.5%, rgba(255, 255, 255, 0) 130%);
}

.verified-content {
  width: 190px;
  margin: 0 auto;
}

.points-tab .normal-text {
  font-weight: bold;
}

#main>ion-content.route-loyalty>.no-padding .loyalty-title-wrap>ion-text:last-child>span {
  font-weight: bold;
}

#main>ion-content.route-loyalty>.no-padding .absolute-content.points-tab img {
  width: 40%;
}

#main>ion-content.route-loyalty>.no-padding .absolute-content.points-tab .points {
  font-size: 4em;
}

.web #main>ion-content.route-loyalty>.no-padding {
  position: fixed;
  left: 0 !important;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto !important;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
  height: 80%;
  overflow: hidden;
  background-color: var(--ion-color-white);
}

.web .route-loyalty .tabs {
  position: relative;
  height: 100%;
  border-radius: inherit;
}

.web .route-loyalty .tabs ion-slides,
ion-slide {
  height: calc(100vh - 350px);
  min-height: 350px;
}

.web ion-content.route-loyalty {
  background-color: #fff;
  background-image: url(/static/media/refer-a-friend.65079366.jpg);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

.web ion-segment-button.segment-button-checked {
  border-bottom-color: var(--ion-color-secondary);
  background-color: var(--okx-background-color);
}

.web .points-tab {
  background: transparent none;
}

/* new */
.profile-points {
  font-weight: bold;
  font-size: 20px !important;
}

.web .loyalty-header{
  padding: 20px;
}

.loyalty-header {
  color: var(--ion-color-primary-contrast);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: var(--ion-padding) var(--ion-padding) 0px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0 0;
  position: relative;
  overflow: hidden;
}

.loyalty-header::after {
  content: '';
  position: absolute;
  left: -30px;
  top: -30px;
  height: 200px;
  width: 200px;
  background-size: contain;
}

.loyalty-header img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
}

.loyalty-header-content {
  display: -webkit-flex;
  display: flex;
  z-index: 2;
}

.loyalty-header-name {
  z-index: 2;
  margin-bottom: 35px;
}

.loyalty-header-name .heading{
  font-size: var(--ion-font-size);
}

.loyalty-header-name .heading_bold{
  font-weight: bold;
}

.loyalty-header-content .qr-code h5 {
  color: var(--ion-color-white);
}

.loyalty-header-content {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.loyalty-header-content>div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.loyalty-header-content>div>div:first-child {
  margin-bottom: 30px;
}

.loyalty-header .subtitle,
.loyalty-header .normal-text,
.loyalty-header .title {
  color: #181818;
  display: block;
}

.loyalty-history,
.loyalty-content {
  padding: var(--ion-padding);

}

.loyalty-history {
  padding-top: 0;
}

.thin-text {
  opacity: 0.7;
  font-size: var(--okx-small-text-font-size) !important;
}

.transactions-wrapper .content-box {
  border: 1px solid var(--ion-color-gray);
  background-color: transparent !important;
}

.transactions-wrapper ion-col {
  padding: 0;
}

.transactions-wrapper .content-box,
.loyalty-rewards-wrapper {
  margin-top: 10px;
  min-height: 70px;
  display: grid;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
}

.loyalty-content-wrapper {
  margin-top: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border: 1px solid var(--ion-color-gray) !important;
  background-color: transparent !important;
}

.points-balance-value {
  font-size: 24px !important;
  line-height: 1;
}

.loyalty-content-wrapper .loyalty-content-value {
  font-size: 20px;
  margin: 0;
  font-weight: bold;
}

/* .border-color {
	color: var(--ion-color-border) !important;
  } */
.loyalty-content-desc {
  margin-top: 10px;
}

.route-loyalty .scrollable-y {
  height: 100%;
}

.web .route-loyalty .scrollable-y {
  overflow-y: auto;
}

.loyalty-reward {
  height: 150px;
  width: 200px;
  background-size: cover;
  border-radius: var(--okx-box-radius);
  position: relative;
  overflow: hidden;
}

.loyalty-rewards-wrapper {
  display: grid;
  grid-gap: 10px;
  gap: 10px;
  grid-auto-flow: column;
  overflow-x: auto;
}

.loyalty-reward-points {
  position: absolute;
  top: 10px;
  right: 0;
  background-color: var(--ion-color-secondary);
  padding: 5px 10px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.loyalty-reward-points .strong-text {
  color: var(--ion-color-white);
}

.loyalty-reward-name {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: var(--ion-color-secondary);
  padding: 10px;
  width: 100%;
  text-align: center;
}

.loyalty-reward-name .strong-text {
  color: #181818;
  text-transform: uppercase;
}

.route-loyalty .no-padding .scrollable-y {
  padding: 0;
}

.loyalty-name {
  color: #181818 !important;
  text-transform: uppercase !important;
  margin-bottom: 10px !important; 
}

.loyalty-points {
  font-size: 22px !important;
}

.loyalty-text-12 {
  font-size: 12px !important;
}

.loyalty-text-10 {
  font-size: 10px !important;
}

.transaction-points-wrapper {
  background-color: rgba(0, 0, 0, 0.17);
  height: 50px;
  width: 50px;
  border-radius: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.transaction-points-wrapper .subtitle {
  line-height: 1;
  margin: auto;
}

.route-loyalty .page-title {
  margin-left: var(--ion-padding);
}

.web .route-loyalty .page-title {
  margin-top: var(--ion-padding);
}

.transaction-label {
  margin-bottom: var(--okx-title-margin) !important;
}
ion-modal.modal-classic {
	--width: 70%;
	--height: 50%;
	--border-radius: 10px;
}

.web ion-modal.modal-classic.quantity-modal {
	--height: 20%;
	--min-height: 210px;
}

.web ion-modal.modal-classic.quantity-modal .modal-classic-content {
	-webkit-justify-content: center;
	        justify-content: center;
}

ion-modal.modal-classic .modal-classic-wrapper {
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	padding: 5px 13px;
	max-height: 100%;
}

ion-modal.modal-classic .modal-classic-content {
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
	overflow-y: auto;
	padding: 12px;
}

ion-modal.modal-classic:not(.allergen-modal):not(.apply-points-modal) .modal-classic-content .absolute-content {
	-webkit-padding-start: 0;
	        padding-inline-start: 0;
	-webkit-padding-end: 0;
	        padding-inline-end: 0;
	padding-left: 0;
	padding-right: 0;
}

ion-modal.modal-classic .modal-classic-closer {
	position: absolute;
	top: 0;
	right: 0;
	padding: 13px;
	cursor: pointer;
	z-index: 1;
}

ion-modal.modal-classic .modal-classic-header,
ion-modal.modal-classic .modal-classic-action {
	-webkit-flex: 0 1 auto;
	        flex: 0 1 auto;
}

ion-modal.modal-classic .modal-classic-header {
	padding: 15px 5px;
}

ion-modal.modal-classic .modal-classic-header h3 {
	font-size: 14px;
	font-weight: bold;
	margin: 0;
}

/* Unlock rewards style */

:root {
  --reward-red: #6e2b13;
  --reward-black: #000;
}

.reward {
  position: relative;
  margin-bottom: 20px;
}

.reward.green .reward-content {
  background-color: var(--ion-color-primary);
}

.reward.red .reward-content {
  background-color: #6e2b13;
  background-color: var(--reward-red);
}

.reward.black .reward-content {
  background-color: #000;
  background-color: var(--reward-black);
}

.reward-content {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.reward-cover {
  position: absolute;
  display: grid;
  place-items: center;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}

.reward-cover ion-icon {
  position: absolute;
  fill: #fff;
  font-size: 42px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.reward-content::before,
.reward-content::after {
  display: block;
  content: '';
  position: absolute;
  top: 8px;
  bottom: 0;
  width: 20px;
  background: radial-gradient(#fff 0px, #fff 6px, transparent 7px, transparent);
  background-size: 20px 20px;
}

.reward-content::before {
  left: -9px;
}

.reward-content::after {
  right: -9px;
}

.reward-content div {
  color: #fff;
}

.reward-spacer {
  -webkit-flex-grow: 0;
          flex-grow: 0;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-basis: 20px;
          flex-basis: 20px;
  min-width: 20px;
}

.reward-info,
.reward-stamp {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  -webkit-flex-basis: auto;
          flex-basis: auto;
  text-align: center;
}

.reward-stamp {
  -webkit-flex-grow: 0;
          flex-grow: 0;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-basis: 85px;
          flex-basis: 85px;
  min-height: 112px;
  min-width: 85px;
  position: relative;
}

.reward-stamp::after {
  display: block;
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 70px;
  height: 70px;
  margin: -38px 0 0 -38px;
  border-radius: 50%;
  border: 3px #fff solid;
  background: transparent url(/static/media/social-icon.ad38e6a9.svg) no-repeat 50% 50%;
  background-size: 40px 40px;
}

.reward:not(.default-font) .reward-title,
.reward:not(.default-font) .reward-subtitle {
  font-family: primary-regular;
}

.reward-title,
.reward-subtitle {
  text-transform: uppercase;
}

.reward-title {
  font-size: 32px;
  font-weight: bold;
}

.reward-subtitle {
  font-size: 12px;
}

.reward-description {
  font-size: 8px;
}

.reward.default-font .reward-title {
  line-height: 28px;
  font-weight: normal;
}

.reward.default-font .reward-subtitle {
  font-weight: bold;
}

.reward-bar {
  height: 41px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  background: var(--ion-color-white);
  margin-top: -4px;
  overflow: hidden;
}

.reward-bar > div:first-child,
.reward-bar > div:last-child {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  -webkit-flex-basis: auto;
          flex-basis: auto;
}

.reward-bar > div:first-child {
  padding-left: 16px;
}

.reward-bar > div:last-child {
  text-align: right;
  padding-right: 12px;
}

.reward-bar ion-button.button-outline {
  /* height: 25px; */
  padding: 10px 5px;
  margin-bottom: 0;
  --border-width: 1px;
  --border-color: rgba(var(--ion-color-gray-rgb), 0.5);
  --border-radius: 3px;
  color: var(--ion-color-secondary);
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

.reward-bar p {
  color: #000;
  font-family: primary-bold;
  font-size: 16px;
  text-align: left;
  text-align: initial;
  text-transform: uppercase;
  margin: 0;
}

ion-modal.modal-classic.reward-modal {
  --width: 85%;
  --height: 70%;
  --border-radius: 10px;
}

ion-modal.modal-classic.reward-modal .modal-classic-wrapper {
  padding: 0;
}

ion-modal.modal-classic.reward-modal .modal-classic-header {
  background-color: var(--ion-color-secondary);
  padding-left: 20px;
}

ion-modal.modal-classic.reward-modal .modal-classic-header h3 {
  color: var(--ion-color-white);
  font-family: primary-bold;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

ion-modal.modal-classic.reward-modal .modal-classic-closer {
  color: var(--ion-color-white);
  font-size: 18px;
}

ion-modal.modal-classic.reward-modal .modal-classic-content {
  padding: 30px 15px;
}

.reward-modal-content {
  padding: 0 30px;
}

.modal-classic-content > div > img,
.reward img {
  object-fit: cover;
  object-position: center;
}

/* ion-content.route-loyalty > .ion-padding .absolute-content {
  background: #fff;
} */
.route-loyalty > .ion-padding{
  position: static !important;
}
.route-loyalty > .ion-padding .absolute-content{
  background-color: #fff;
}
ion-content.route-loyalty > .ion-padding .absolute-content .big-number {
  font-weight: 600;
  font-size: 5em;
  color: var(--ion-color-primary);
}



#main > ion-content.route-rewards > .ion-padding .absolute-content ion-item {
  --border-color: var(--ion-color-light);
}

#main > ion-content.route-rewards > .ion-padding .absolute-content .inline-input > ion-text {
  -webkit-flex: 0 0 25%;
          flex: 0 0 25%;
}

.reward-modal-qr {
  /* background: transparent url(../../assets/images/qr-dummy.png) no-repeat 50% 0; */
  background-size: contain;
  width: 100%;
  height: 200px;
  border: 10px solid var(--ion-color-white);
}

.reward-item ion-item ion-input {
  --placeholder-color: var(--ion-color-primary);
  --placeholder-opacity: 1;
  text-transform: none;
  text-transform: initial;
  -webkit-margin-start: 20px;
          margin-inline-start: 20px;
}

.web .static.route-rewards {
  background: transparent none;
}

.web ion-content.route-rewards {
  --background: transparent none;
  --ion-color-white: transparent;
}

.web #main > ion-content.route-rewards > .ion-padding .absolute-content {
  border-radius: inherit;
  overflow: hidden;
  padding: 0;
}

.web #main > ion-content.route-rewards > .ion-padding .absolute-content .flex-min:first-of-type {
  padding: var(--ion-padding) var(--ion-padding) 0 var(--ion-padding);
}

.web #main > ion-content.route-rewards > .ion-padding .absolute-content > .scrollable-y {
  padding: 0 var(--ion-padding);
}

.web .clickup {
  position: absolute;
}

:root {
	--clickup-initial-height: 50px;
	--clickup-open-height: 50%;
}

.clickup {
	position: absolute;
	position: fixed;
	left: 0;
	top: calc(100% - 50px);
	top: calc(100% - var(--clickup-initial-height));
	right: 0;
	bottom: 0;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: stretch;
	        align-items: stretch;
	transition: top 200ms ease-out;
	z-index: 5;
}

.clickup.open {
	top: calc(100% - 50%);
	top: calc(100% - var(--clickup-open-height));
}

.clickup-bar {
	background-color: var(--ion-color-secondary);
	-webkit-flex: 0 0 50px;
	        flex: 0 0 50px;
	-webkit-flex: 0 0 var(--clickup-initial-height);
	        flex: 0 0 var(--clickup-initial-height);
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	cursor: pointer;
}

.clickup-bar > div {
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
	text-align: right;
	text-transform: uppercase;
	/* color: var(--ion-color-white); */
	color: #fff;
	font-weight: normal;
}

.clickup-caret {
	height: 20px;
	background: transparent url(/static/media/caret-up-white.c66d710b.svg) no-repeat 5px 50%;
}

.clickup.open .clickup-caret {
	background-image: url(/static/media/caret-down-white.2c7477c5.svg);
}

.clickup-content {
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
	background-color: #fff;
	position: relative;
}

:root {
	--voucher-red: #6e2b13;
	--voucher-black: #000;
}

.voucher {
	margin-bottom: 20px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position-y: 50%;
}

.voucher.green .voucher-content {
	background-color: var(--ion-color-primary);
}

.voucher.red .voucher-content {
	background-color: #6e2b13;
	background-color: var(--voucher-red);
}

.voucher.black .voucher-content {
	background-color: #000;
	background-color: var(--voucher-black);
}

.voucher-content {
	position: relative;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.voucher-content::before, .voucher-content::after {
	display: block;
	content: '';
	position: absolute;
	top: 8px;
	bottom: 0;
	width: 20px;
	background: radial-gradient(
		#fff 0px, 
		#fff 6px, 
		transparent 7px,
		transparent
	);
	background-size: 20px 20px;
}

.voucher-content::before {
	left: -9px;
}

.voucher-content::after {
	right: -9px;
}

.voucher-content div {
	color: #fff;
}

.voucher-spacer {
	-webkit-flex-grow: 0;
	        flex-grow: 0;
	-webkit-flex-shrink: 0;
	        flex-shrink: 0;
	-webkit-flex-basis: 20px;
	        flex-basis: 20px;
	min-width: 20px;
}

.voucher-info, .voucher-stamp {
	-webkit-flex-grow: 1;
	        flex-grow: 1;
	-webkit-flex-shrink: 1;
	        flex-shrink: 1;
	-webkit-flex-basis: auto;
	        flex-basis: auto;
	text-align: center;
}

.voucher-stamp {
	-webkit-flex-grow: 0;
	        flex-grow: 0;
	-webkit-flex-shrink: 0;
	        flex-shrink: 0;
	-webkit-flex-basis: 85px;
	        flex-basis: 85px;
	min-height: 112px;
	min-width: 85px;
	position: relative;
}

.voucher-stamp::after {
	display: block;
	content: '';
	position: absolute;
	left: 50%;
	top: 50%;
	width: 70px;
	height: 70px;
	margin: -38px 0 0 -38px;
	border-radius: 50%;
	border: 3px #fff solid;
	background: transparent url(/static/media/social-icon.ad38e6a9.svg) no-repeat 50% 50%;
	background-size: 40px 40px;
}

.voucher:not(.default-font) .voucher-title, .voucher:not(.default-font) .voucher-subtitle {
	font-family: 'Roboto';
}

.voucher-title, .voucher-subtitle {
	text-transform: uppercase;
}

.voucher-title {
	font-size: 32px;
	font-weight: bold;
}

.voucher-subtitle {
	font-size: 12px;
}

.voucher-description {
	font-size: 8px;
}

.voucher.default-font .voucher-title {
	line-height: 28px;
	font-weight: normal;
}

.voucher.default-font .voucher-subtitle {
	font-weight: bold;
}

.voucher-bar {
	display: -webkit-flex;
	display: flex;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	box-shadow: 0 2px 5px rgba(0,0,0,0.15);
}

.voucher-bar > div:first-child, .voucher-bar > div:last-child {
	-webkit-flex-grow: 1;
	        flex-grow: 1;
	-webkit-flex-shrink: 1;
	        flex-shrink: 1;
	-webkit-flex-basis: auto;
	        flex-basis: auto;
}

.voucher-bar > div:first-child {
	padding-left: 16px;
}

.voucher-bar > div:last-child {
	text-align: right;
	padding-right: 12px;
}

.voucher-bar ion-button {
	margin-bottom: 4px;
	--border-width: 1px;
	--border-color: var(--ion-color-light);
}

.voucher-bar p {
	font-size: var(--okx-small-text-font-size);
}

ion-modal.modal-classic.voucher-modal {
	--width: 85%;
	--height: 70%;
	--border-radius: 10px;
}

ion-modal.modal-classic.voucher-modal .modal-classic-wrapper {
	padding: 0;
}

ion-modal.modal-classic.voucher-modal .modal-classic-header {
	background-color: var(--ion-color-secondary);
	padding-left: 20px;
}

ion-modal.modal-classic.voucher-modal .modal-classic-header h3 {
	color: var(--ion-color-primary);
	text-transform: uppercase;
}

ion-modal.modal-classic.voucher-modal .modal-classic-closer {
	color: var(--ion-color-primary);
}

ion-modal.modal-classic.voucher-modal .modal-classic-content {
	padding: 30px 15px;
}

.voucher-modal-content {
	padding: 0 30px;
}

.modal-classic-content > div > img, .voucher img {
	object-fit: cover;
	object-position: center;
}

#main > ion-content.route-vouchers > .ion-padding .absolute-content .voucher-modal-content > ion-text > span {
	font-size: 23px;
}

#main > ion-content.route-vouchers > .ion-padding .absolute-content ion-item {
	--border-color: var(--ion-color-light);
}

.voucher-modal-qr {
	/* background: transparent url(../../assets/images/qr-dummy.png) no-repeat 50% 0; */
	background-size: contain;
	width: 100%;
	height: 200px;
}

.voucher-item ion-item ion-input {
	text-transform: uppercase;
}

.web .static.route-vouchers {
	background: transparent none;
}

.web ion-content.route-vouchers {
	--background: transparent none;
	--ion-color-base: transparent;
}

.web #main > ion-content.route-vouchers > .ion-padding {
	height: 80%;
	top: 50%;
	-webkit-transform: translate(0, -50%);
	        transform: translate(0, -50%);
}

.web #main > ion-content.route-vouchers > .ion-padding .absolute-content {
	border-radius: inherit;
	overflow: hidden;
	padding: 0;
}

.web #main > ion-content.route-vouchers > .ion-padding .absolute-content .flex-min:first-of-type {
	padding: var(--ion-padding) var(--ion-padding) 0 var(--ion-padding);
}

.web #main > ion-content.route-vouchers > .ion-padding .absolute-content > .scrollable-y {
	padding: 0 var(--ion-padding);
}

.web .clickup {
	position: absolute;
}

.no-data-second-wrapper {
  display: -webkit-flex;
  display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.no-data-second-topic {
  min-width: 310px;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.no-data-second-topic > img {
  width: calc(100% - 20%);
  object-fit: cover;
  opacity: 0.4;
}
.validate-button .validate-status {
  background-color: var(--ion-color-danger);
  color: var(--ion-color-white);
  border-radius: 15px;
  padding: 5px;
  width: 120px;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.validate-button.valid .validate-status {
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-secondary-contrast);
}

.web .validate-button .validate-status {
  padding: 8px;
}

.validate-button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.input-avatar {
	display: none;
}

.profile-image-content {
	width: 96px;
	height: 96px;
	margin: auto;
}

.avatar-image-wrapper {
	position: relative;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	margin-bottom: 30px;
}

.avatar-delete {
	position: absolute;
	top: 15%;
	right: 115px;
	-webkit-transform: translate(0, -50%);
	        transform: translate(0, -50%);
}

.avatar-photo {
	position: absolute;
	top: 50%;
	right: -20px;
	-webkit-transform: translate(0, -50%);
	        transform: translate(0, -50%);
	background: rgba(var(--ion-color-secondary-rgb), 1);
	border-radius: 50%;
	width: 40px;
	height: 40px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.avatar-circle {
	border: 10px solid var(--okx-circle-color);
	border-radius: 65px;
	position: relative;
}

.account-inputs .ion-text-wrap>ion-note {
	color: var(--ion-color-primary);
}

.account-inputs ion-button.link {
	font-size: var(--okx-small-text-font-size);
}

.line {
	height: 1px;
	background-color: var(--ion-color-tertiary);
	margin: 20px 0;
}

.top {
	margin-top: 25px;
}

#main>ion-content.route-account>.ion-padding ion-input>input,
#main>ion-content.route-account>.ion-padding input {
	font-weight: bold;
}

#main>ion-content.route-account>.ion-padding ion-label,
#main>ion-content.route-account>.ion-padding label {
	font-weight: 500;
	font-weight: var(--okx-small-text-font-size)
}

#main>ion-content.route-account>.ion-padding .mbsc-select-input.mbsc-control {
	border-bottom: 0;
	background-color: transparent;
	border: none;
}

@supports (-webkit-overflow-scrolling: touch) {

	/* iOS */
	.avatar-delete {
		position: absolute;
		top: 15%;
		right: 95px;
		-webkit-transform: translate(0, -50%);
		        transform: translate(0, -50%);
	}
}

ion-content.route-account ion-input {
	-webkit-flex: unset;
	        flex: unset;

}

ion-content.route-account ion-input input {
	--padding-top: 0;
	--padding-bottom: 0
}


.web #main>ion-content.route-account>.ion-padding {
	overflow-y: auto;
}

.web .route-account .ion-padding .frm-wrapper,
.web .route-account .ion-padding .top-medium {
	position: absolute;
}

.web .route-account .ion-padding .frm-wrapper {
	left: 32px;
	right: 32px;
	top: 40px;
	bottom: 130px;
	overflow-y: auto;
	margin-bottom: 10px;
}

.web .route-account .ion-padding .top-medium {
	left: 0;
	right: 0;
	bottom: 0;
	padding: 0 32px 32px;
}

.web .route-account .ion-padding ion-button:last-child {
	margin-bottom: 0;
}

.web .avatar-photo,
.web .avatar-delete {
	cursor: pointer;
}

.route-account .has-value.sc-ion-input-ios-h .input-clear-icon.sc-ion-input-ios {
	height: 20px;
	width: 20px;
}

.route-account .data-picker-input {
	padding: 5px 0 0 0;
	background-color: transparent;
}

.route-account .absolute-content {
	overflow: auto;
	height: 100%;
}
.mbsc-mobiscroll .mbsc-fr-hdr, .mbsc-mobiscroll .mbsc-fr-btn {
	color: var(--ion-color-primary);
}

.mbsc-mobiscroll .mbsc-cal-days > div {
	color: var(--ion-color-primary);
	border-color: var(--ion-color-primary);
}

.mbsc-mobiscroll.mbsc-no-touch:not(.mbsc-ev-cal) .mbsc-cal-cell:not(.mbsc-disabled):hover .mbsc-cal-cell-txt, .mbsc-mobiscroll.mbsc-no-touch .mbsc-cal-day:not(.mbsc-disabled) .mbsc-cal-day-date:hover, .mbsc-mobiscroll .mbsc-cal-cell:focus .mbsc-cal-cell-txt {
	background: var(--ion-color-light-tint);
}

.mbsc-mobiscroll .mbsc-fr-btn.mbsc-active, .mbsc-mobiscroll.mbsc-no-touch .mbsc-fr-btn-e:not(.mbsc-disabled):hover {
	background: var(--ion-color-light);
}

.mbsc-mobiscroll .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
	background: var(--ion-color-primary);
}

.date-picker-wrapper {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}

.date-picker-label {
	font-size: 10px;
	font-weight: normal;
	color: var(--okx-normal-text-color);
	color: #959595;
	/* margin-left: 2px; */
	transition: .1s ease-in;
}

.date-picker-label--red {
	color: var(--ion-color-primary);
}

.data-picker-input {
	border: 0;
	outline: 0;
	color: var(--ion-color-dark);
	padding: 10px 8px 10px 0;
	background-color: transparent;
	font-family: var(--ion-default-font-bold);
}


.mbsc-material .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
	/* background: var(--ion-color-primary); */
	background: rgba(255, 26, 75, .9);
	color: #fff;
}

.mbsc-material .mbsc-fr-btn {
	color: var(--ion-color-primary);
}

.mbsc-ios .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
	background: var(--ion-color-primary);
	border-color: var(--ion-color-primary);
}

.mbsc-mobiscroll .mbsc-sc-whl-l {
	display: none;
}
.mbsc-comp.time-picker {
  width: 100%;
  color: var(--ion-color-dark);
  padding: 10px 8px 10px 0;
  border: 0;
  border-bottom: 1px solid;
  border-color: rgba(0,0,0,0.13);
  border-color: var(--ion-item-border-color,var(--ion-border-color,var(--ion-color-step-150,rgba(0,0,0,0.13))));
  outline: none;
}

.mbsc-comp.time-picker::-webkit-input-placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-comp.time-picker:-ms-input-placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-comp.time-picker::placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-ios .mbsc-sc-itm-sel, .mbsc-ios .mbsc-sc-whl-gr-3d {
  /* color: #000; */
  color: #3880ff;
  color: var(--ion-color-primary,#3880ff);
}

.mbsc-material .mbsc-sc-lbl {
  color: #3880ff;
  color: var(--ion-color-primary,#3880ff);
}

.mbsc-fr-btn1 .mbsc-fr-btn-e .mbsc-fr-btn {
  color: #3880ff;
  color: var(--ion-color-primary,#3880ff);
}

/* .mbsc-sc-itm .mbsc-btn-e .mbsc-sc-itm-sel {
  color: var(--ion-color-primary,#3880ff);
} */

.mbsc-material .mbsc-fr-btn-cont .mbsc-fr-btn {
  color: #3880ff;
  color: var(--ion-color-primary,#3880ff);
}

.mbsc-fr-btn-cont {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.mbsc-sc-itm-sel {
  color: #3880ff;
  color: var(--ion-color-primary,#3880ff);
  font-weight: 600;
}

.time-picker-label {
  font-size: 9px;
  color: var(--okx-normal-text-color);
  /* margin-left: 2px; */
}

.mbsc-mobiscroll .mbsc-sc-lbl {
  color: #3880ff;
  color: var(--ion-color-primary,#3880ff); 
}

.mbsc-material .mbsc-sc-whl-l {
  display: none;
}

.mbsc-select-input.mbsc-control {
  width: 100%;
  color: var(--ion-color-dark);
  padding: 10px 8px 10px 0;
  border: 0;
  border-bottom: 1px solid;
  border-color: rgba(0,0,0,0.13);
  border-color: var(--ion-item-border-color,var(--ion-border-color,var(--ion-color-step-150,rgba(0,0,0,0.13))));
  outline: none;
}

.mbsc-select-input.mbsc-control::-webkit-input-placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-select-input.mbsc-control:-ms-input-placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-select-input.mbsc-control::placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-ios .mbsc-fr-btn {
  color: #3880ff;
  color: var(--ion-color-primary,#3880ff);
  font-size: 17px;
}

.select-picker-label {
  display: block;
  font-size: 9px;
  color: var(--okx-normal-text-color);
  margin-top: 10px;
  font-weight: normal;
}

.select-picker-label--primary {
  color: #3880ff;
  color: var(--ion-color-primary,#3880ff);
}

.select-picker-label.select-picker-label--feedback {
  font-weight: bold;
  margin-left: 0;
}

.select-picker-input--feedback + input.mbsc-select-input.mbsc-control {
  color: var(--ion-color-gray);
  font-size: 10px;
  font-weight: normal;
  border-color: rgba(0,0,0,0.13);
  border-color: var(--ion-color-black,var(--ion-color-black,var(--ion-color-step-150,rgba(0,0,0,0.13))));
}

.mbsc-fr-btn-w .mbsc-fr-btn-s {
  border: 0;
}

.mbsc-ios.mbsc-fr-center .mbsc-fr-btn-w {
  border: 0;
}

.mbsc-ios.mbsc-fr-center .mbsc-fr-btn-cont {
  border: 1px solid rgba(0,0,0,0.13);
  border: 1px solid var(--ion-item-border-color,var(--ion-border-color,var(--ion-color-step-150,rgba(0,0,0,0.13))));
}

.mbsc-ios .mbsc-sc-whl-gr-3d .mbsc-sc-itm-3d {
  color: #a3a3a3;
}

.mbsc-ios .mbsc-sc-itm-sel {
  color: #000;
  color: #3880ff !important;
  color: var(--ion-color-primary,#3880ff) !important;
}
ion-content div.refer-a-friend {
	background-image: url(/static/media/refer-a-friend.65079366.jpg);
	background-repeat: no-repeat;
	background-position: 50% 100%;
	background-size: cover;
}
  
  ion-content div.refer-a-friend::after {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	height: 240px;
	z-index: 1;
	background-image: linear-gradient(rgba(255,255,255,1) 10%, rgba(255,255,255,0) 90%);
  }
  
  .raf-card {
	border-radius: var(--ion-card-radius);
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	margin: 0;
  }
  
  .raf-card ion-card-content {
	padding: 30px;
	font-size: var(--ion-font-size);
  }
  .route-refer-a-friend .raf-card{
	height: 100%;
  }
  .route-refer-a-friend .raf-card ion-card-content{
	height: 100%;
	overflow-y: auto;
  }
  .raf-card ion-card-content .raf-info-title {
	padding: 0 2px;
	font-weight: bold;
  }
  
  #main > ion-content.route-refer-a-friend > .ion-padding ion-item {
	--border-color: var(--ion-color-light);
  }
  
  .web .static.route-refer-a-friend {
	background: transparent none;
  }
  
  .web ion-content.route-refer-a-friend {
	--background: transparent none;
	background-color: #fff;
	background-image: url(/static/media/refer-a-friend.65079366.jpg);
	background-position: 50% 100%;
	background-size: cover;
	background-repeat: no-repeat;
  }
  
  .web #main > ion-content.route-refer-a-friend > .ion-padding .absolute-content {
	background: transparent none;
	border-radius: inherit;
	overflow: hidden;
  }
  
  
  
  .web .route-refer-a-friend .raf-card {
	border-radius: inherit;
	box-shadow: none;
	max-height: 100% !important;
  }
  
  .refer-fiend-email{
	opacity: 0.8;
  }
.feedback-label {
	font-size: 12px;
	margin-right: 5px;
}

.star-holder {
	padding: 10px 0px;
}

.star {
	font-size: 20px;
	color: var(--ion-feedback-star);
	margin-right: 15px;
}

.star-active {
	color: var(--ion-color-secondary) !important;
}

.web .star {
	cursor: pointer;
}

.star:last-child {
	margin: 0;
}

.box-holder-top {
	margin-top: 20px;
}

.separator {
	height: 1px;
	background-color: var(--ion-color-gray);
	margin: 0 5px;
}



#main>ion-content.route-feedback>.ion-padding .box-holder .inline-input {
	border-bottom: 1px solid var(--ion-color-light);
}

#main>ion-content.route-feedback>.ion-padding .box-holder .inline-input:last-child {
	border-bottom: 0;
}

#main>ion-content.route-feedback>.ion-padding .box-holder .inline-input {
	padding: 10px;
}

#main>ion-content.route-feedback>.ion-padding .inline-input label {
	-webkit-flex: none;
	        flex: none;
	font-size: var(--ion-font-size);
	-webkit-margin-end: 50px;
	        margin-inline-end: 50px;
	margin-top: 0;
}

#main>ion-content.route-feedback>.ion-padding .inline-input:first-child .feedback-label {
	margin: 0;
}

.alert-wrapper * {
	color: var(--ion-color-primary) !important;
}

.alert-wrapper {
	background-color: var(--okx-background-color) !important;
}


.alert-message.sc-ion-alert-ios {
	color: var(--ion-color-primary);
	font-family: var(--ion-default-font-thiner);
}

#main>ion-content.route-feedback>.ion-padding .mbsc-select-input.mbsc-control {
	border: 0;
}

ion-content.route-feedback .box-holder {
	padding: 0;
}

ion-content.route-feedback .box-holder.inline-input {
	padding: 10px;
}

ion-content.route-feedback .box-holder ion-item {
	--border-color: transparent;
}

ion-content.route-feedback .box-holder ion-list {
	padding: 0;
}

ion-content.route-feedback .box-holder ion-item:first-child {
	border-bottom: 1px solid var(--ion-color-light);
}

#main>ion-content.route-feedback>.ion-padding .select-picker-input--feedback+input.mbsc-select-input.mbsc-control {
	font-size: 12px;
}

#main>ion-content.route-feedback>.ion-padding .top-medium {
	margin-top: 10px;
}

#main>ion-content.route-feedback>.ion-padding .top-medium .inline-input.inline-input--feedback {
	-webkit-align-items: center;
	        align-items: center;
	padding-top: 10px;
	border-bottom: 0;
}

.web #main>ion-content.route-feedback>.ion-padding .top-medium .inline-input.inline-input--feedback {
	-webkit-align-items: center;
	        align-items: center;
	padding-top: 10px;
	border-bottom: 0;
}

.web #main>ion-content.route-feedback>.ion-padding {
	padding: 0 0 0 var(--ion-padding);
}

.web #main>ion-content.route-feedback>.ion-padding>div {
	overflow-y: auto !important;
	padding: var(--ion-padding) var(--ion-padding) var(--ion-padding) 0;
	height: 100%;
}

.web .route-feedback .ion-padding .frm-wrapper {
	overflow-y: auto;
}

.route-feedback .ion-padding .action-wrapper {
	left: 0;
	right: 0;
	bottom: 0;
}

.web .route-feedback .ion-padding ion-button:last-child {
	margin-bottom: 0;
}

.route-feedback ion-checkbox {
	margin: auto 20px;
}

.route-feedback .ion-padding .heading-wrapper  {
	margin-bottom: 10px;
}
.tabs {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  overflow: hidden;
  z-index: 0;
}

.tabs .segment-holder {
  position: sticky;
  position: -webkit-sticky;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  top: 0;
  z-index: 2;
}

/* .tabs ion-segment-button {
  min-width: 70px;
} */

.tabs ion-segment-button {
	--indicator-color: transparent;
  border-color: var(--okx-background-color);
}

.tabs ion-segment-button > ion-label {
	text-transform:none;
	font-weight: normal;
}

.tabs ion-slides, ion-slide {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  margin: 0;
  height: calc(100vh - 163px);
}

.loyalty-bar > div {
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
	text-align: left;
}

.loyalty-bar > div:first-child {
	-webkit-flex: 0 0 20px;
	        flex: 0 0 20px;
	margin-left: 24px;
}

.loyalty-bar > div:nth-of-type(2) > ion-label {
	font-weight: bold;
}

.loyalty-bar > div:last-child {
	text-align: right;
}

.loyalty-bar .loyalty-badge {
	-webkit-transform: scale(0.6);
	        transform: scale(0.6);
	margin: 0 0 0 24px;
}

.loyalty-bar .loyalty-balance {
	margin: 0 24px 0 0;
}
.loyalty-badge {
	width: var(--okx-loyalty-badge-width);
	position: relative;
	border-radius: 50%;
	padding: var(--okx-loyalty-badge-inner-padding);
	background-color: #fcba29;
	border: 2px solid #c58d13;
	margin: 20px auto;
}

.loyalty-badge > div {
	padding-top: calc(100% - 4px);
	border-radius: 50%;
	border: inherit;
}

.loyalty-badge > div::before, .loyalty-badge > div::after {
	content: '';
	display: block;
	position: absolute;
	right: 6px;
	bottom: 8px;
	background-color: #fcba29;
	width: 2px;
	height: 5px;
	-webkit-transform: rotate(-40deg);
	        transform: rotate(-40deg);
}

.loyalty-badge > div::after {
	right: 10px;
	bottom: 6px;
}

.loyalty-badge > div ion-icon {
	--lb-icon-offset: calc(var(--okx-loyalty-badge-width) /2 - var(--okx-loyalty-badge-inner-padding));
	position: absolute;
	left: var(--lb-icon-offset);
	top: var(--lb-icon-offset);
	font-size: 20px;
	margin: -9px 0 0 -8px;
	color: #c58d13;
}

.loyalty-bar {
	position: absolute;
	width: 100vw;
	height: var(--okx-loyalty-bar-height);
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-flex: 0 0 auto;
	        flex: 0 0 auto;
	background-color: #fff;
	box-shadow: 0 0 15px rgba(0,0,0,0.2);
	left: 0;
	bottom: 0;
	z-index: 2;
}

.web .loyalty-bar {
	position: static;
	width: auto;
}
.history-content {
  text-align: center;
  padding: var(--ion-padding);
  height: 100%;
  overflow: auto;
  background-color: var(--okx-background-color);
}

.history-item {
  margin-bottom: 10px;
}

.history-item h2 {
  margin-top: 4px;
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1;
}

.history-item ion-col:first-child {
  /* text-align: left; */
  text-align: justify;
  cursor: pointer;
  -webkit-align-self: center;
          align-self: center;
}

.history-item ion-text span.small-text {
  text-transform: none;
  text-transform: initial;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.history-item ion-col p:first-child {
  color: var(--ion-color-gray);
  font-size: 8px;
  margin-bottom: 8px;
  font-weight: bold;
}

.history-item ion-col p:last-child {
  color: var(--ion-color-medium);
  font-size: 9px;
  margin-top: 2px;
  margin-bottom: 10px;
}

.history-item ion-col:last-child p {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  white-space: nowrap;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  text-align: right;
  font-size: var(--ion-font-size);
  margin-top: 25px;
  font-weight: bold;
  color: var(--ion-color-primary);
  margin: 0;
}

.history-item ion-col:last-child p img {
  width: 20px;
  max-width: 100%;
  margin-right: 10px;
}

.history-item ion-col:last-child p.redeem {
  /* text-align: right; */
  font-size: 15px;
  /* margin-top: 25px; */
  /* font-weight: 500; */
  /* color: var(--ion-color-danger); */
  margin: 0;
}



.order-button-wrapper .order-button {
  padding: 3px 8px 2px 8px;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 9px;
  cursor: pointer;
}

.order-button-wrapper .order-button ion-icon {
  font-size: 10px;
}

.order-location-name {
  text-transform: capitalize;
}


.web #main>ion-content.route-history>.no-padding {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
  max-height: 80%;
  overflow: hidden;
  width: var(--okx-bsmodal-width-lg);
}

.web .route-history .tabs {
  position: relative;
  height: 100%;
  border-radius: inherit;
}


.no-data-img {
  margin: 30px 0 30px 0;
}

.item-status-completed {
  color: var(--ion-color-tertiary);
  border: 1px solid var(--ion-color-tertiary);
}

.item-status-incompleted {
  color: var(--ion-color-danger);
  border: 1px solid var(--ion-color-danger);
}
.order-content-wrapper ion-row {
	border-color: var(--ion-color-primary);
  }
  .order-content-wrapper{
	position: relative;
  }
  
  
  
  .order-content-wrapper .basket-item-wrapper .basket-item-service-charge-row {
	cursor: pointer;
  }
  .order-content-wrapper .basket-item-wrapper-0 ion-row:first-child{
	border-top: 0;
  }
  .order-content-wrapper ion-row{
	border-top: 1px solid var(--ion-color-light);
	padding: 10px 20px;
  }
  
  .web .order-summary-sidebar .order-content-wrapper .subtotal-bordered-bottom {
	border-top: 1px solid var(--okx-border-color);
  }
  
  .total-bordered-bottom {
	border-bottom: 2px solid var(--okx-border-color);
  }
  
  ion-col.grow {
	-webkit-flex-grow: 3.5;
	        flex-grow: 3.5;
  }
  
  .order-content-wrapper .basket-item-wrapper + ion-row > ion-col > ion-text > span {
	font-weight: normal;
  }
  
  .add-items-btn {
	font-size: 11px;
	font-weight: 400 !important;
	text-transform: uppercase;
	margin: 10px 0;
	height: 20px;
  }
  
  .add-items-btn.ion-color-black {
	--ion-color-base: var(--ion-color-secondary);
  }
  
  .total-bordered-bottom {
	border-bottom: 1px solid var(--ion-color-black);
  }
  
  .total-bordered-top {
	border-top: 1px solid var(--ion-color-black);
  
  }
  
  .subtotal-bordered-bottom {
	border-bottom: 1px solid var(--okx-border-color);
  }
  
  ion-col.grow {
	-webkit-flex-grow: 3.5;
	        flex-grow: 3.5;
  }
  
  ion-modal.modal-classic.remove-apply-voucuher {
	--width: 70%;
	--height: 20%;
	--border-radius: 10px;
  }
  
  .web ion-modal.modal-classic.remove-apply-voucuher {
	--width: 500px;
	--height: 15vh;
	--min-height : 110px;
  }
  
  .web ion-modal.modal-classic.remove-apply-voucuher .modal-classic-header {
	height: 100%;
	display: grid;
	place-content: center;
	text-transform: uppercase;
  }
  
.incrementer {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
  }
  
  .incrementer-decrease,
  .incrementer-incease {
	-webkit-flex: 0 0 var(--okx-incrementer-button-width);
	        flex: 0 0 var(--okx-incrementer-button-width);
	height: var(--okx-incrementer-button-width);
	--padding-start: 0;
	--padding-end: 0;
	--border-width: 1px;
	--border-style: none;
	--background: transparent;
	font-size: 20px;
  
	--color: var(--ion-color-secondary);
	margin: 0;
  }
  
  .incrementer-quantity {
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
	padding-left: var(--okx-incrementer-button-width);
	padding-right: var(--okx-incrementer-button-width);
  }
  
  .incrementer-quantity-solo {
	-webkit-flex: 0 0 45px;
	        flex: 0 0 45px;
	text-align: center;
  }
  
  .incrementer-quantity-value {
	font-weight: bold;
	color: var(--ion-color-primary) !important;
  }
  
  .incrementer-note {
	font-size: var(--okx-small-text-font-size);
	color: var(--ion-color-gray);
  }
  
  .web .incrementer-decrease,
  .web .incrementer-incease {
	--color: var(--ion-color-primary);
  }
  
  .route-apply-points .incrementer{
	background: var(--ion-color-primary);
	height: 240px;
	width: 100%;
	position: absolute;
	left: 0;
	padding: 30px;
  }
  
  .route-apply-points .incrementer-decrease,
  .route-apply-points .incrementer-incease{
	--background: var(--ion-color-secondary);
	color: white;
	border: 1px solid white;
  }
  
  .route-apply-points .incrementer-quantity-value,
  .route-apply-points .incrementer-quantity-unit,
  .route-apply-points .incrementer-note,
  .route-apply-points .incrementer-content{
	color: var(--ion-color-tertiary) !important;
  }
.service-charge-incrementer-wrapper {
  padding: 20px 0;
  margin: 20px 0;
  background-color: var(--ion-color-white);
}
.service-charge-incrementer-wrapper p {
  text-align: center;
}
.service-charge-incrementer-wrapper .incrementer-note{
  font-size: 25px;
  font-weight: bold;
  color: var(--ion-color-primary);
}
.incrementer-buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  gap: 20px;
  padding: 0 32px;
  position: absolute;
  width: 100%;
}
.service-charge-modal  .modal-classic-closer{
  display: none;
}
.service-charge-modal .absolute-content,
.service-charge.absolute-content {
  padding: 30px 0;
}
.service-charge .incrementer {
  padding: 0 32px;
}
.service-charge-content .incrementer-quantity-value{
  color: var(--ion-color-primary) !important
}
.service-charge-incrementer-wrapper .incrementer-quantity {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 25px;
}
.service-charge .incrementer ion-button {
  height: 44px;
  width: 44px;
  margin: 10px;
  --border-color: transparent;
  --border-style:solid;
}
.service-charge .title,
.service-charge .normal-text,
.service-charge-buttons {
  margin: 0 32px;
  display: block;
}

.service-charge-modal .modal-classic-content,
.service-charge-modal .modal-classic-wrapper {
  padding: 0 !important;
}
.service-charge-item {
  pointer-events: none;
}
.route-order .service-charge-item,
.route-order-summary .service-charge-item {
  cursor: pointer;
  pointer-events: all;
}

/* .history-details { */
	/* background: transparent url(../../assets/images/Click&Collect.jpg)  no-repeat 50% 100%; */
	/* background: transparent url(../../assets/images/Click&Collect.jpg) no-repeat 50% 100%/auto;
	background-size: 100% auto;
	z-index: 1;
} */

.history-details::after {
	position: absolute;
	left: 0; top: 0; right: 0; bottom: 0;
	display: block;
	content: '';
	background-image: linear-gradient(#fff 70%, rgba(255,255,255,0) 105%);
}

.history-details ion-grid {
	-webkit-padding-start: 0;
	        padding-inline-start: 0;
	-webkit-padding-end: 0;
	        padding-inline-end: 0;
}

.history-details ion-row {
	/* border-bottom: 1px solid var(--okx-border-color); */
	color: var(--ion-color-dark);
	font-weight: bold;
}

.history-details .no-border, .history-details ion-row.no-border-
 {
	border-bottom: 0 none;
}

.history-details .okx-box-footer {
	border-top: 2px solid var(--okx-border-color);
	padding-left: 0;
	margin-left: 0;
	padding-right: 0;
	margin-right: 0;
}

/* .history-details .okx-box-footer ion-row:not(ion-row:last-of-type) {
	border-bottom: 0 none;
}

.history-details .okx-box-header-grid ion-row:last-of-type {
	border: 0;
} */

.history-details .okx-box-footer ion-row {
	border-bottom: 0 none;
}

/* .history-details .okx-box-header-grid ion-row:nth-of-type(1n) {
	border: 0;
} */

#main > ion-content.route-history-details .order-content-wrapper:last-of-type {
	border-bottom-width: 1px;
}

#main > ion-content.route-history-details .okx-box-header-grid:last-of-type {
	border-bottom-width: 0px;
}

#main > ion-content.route-history-details .order-summary-sidebar .order-content-wrapper .subtotal-bordered-bottom {
	border-top: 1px solid var(--okx-border-color);
}

.web #main > ion-content.route-history-details {
	--background: transparent;
	--ion-color-white: transparent;
	background-color: #fff;
	/* background-image: url(../../assets/images/Click&Collect.jpg); */
	background-size: cover;
	background-position: 100% 0;
	background-repeat: no-repeat;
}

.web #main > ion-content.route-history-details > .ion-padding {
	position: absolute;
	left: 50%;
	top: 50%;
	/* bottom: 50px; */
	width: var(--okx-bsmodal-width-lg);
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	margin-left: 0;
	background-color: #fff;
	border-radius: var(--okx-box-radius);
	box-shadow: 0 0 32px rgba(0,0,0,0.25);
	padding: 64px;
	min-height: 75%;
}

.web #main > ion-content.route-history-details > .ion-padding > .absolute-content.history-details {
	background: none;
}

.web #main > ion-content.route-history-details > .ion-padding > .absolute-content.history-details:after {
	border-radius: var(--okx-box-radius);
	box-shadow: 0 0 32px rgba(0,0,0,0.25);
}

.web #main > ion-content.route-history-details > .ion-padding > .absolute-content.history-details > div.scrollable-y {
	height: 100%;
}

.web .history-details .okx-box,
.web .history-details .okx-box-header {
	border-color: var(--okx-border-color);
	border-width: 1px;
}

.web .history-details .okx-box-header p.light-text br {
	display: none;
}

.web .history-details .okx-box-header p.light-text {
	color: var(--ion-color-dark);
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 7px 0;
}

.web .history-details .okx-box-header p.light-text ion-text:nth-of-type(odd) strong {
	font-weight: normal;
}

.web .history-details .okx-box-header p.light-text ion-text:nth-of-type(even) {
	text-align: right;
	font-weight: bold;
}

@media screen and (min-width: 1441px) {
    .web #main > ion-content.route-history-details > .ion-padding {
        min-height: 65%;
    }
}
:root {
	--okx-puller-height: 30px;
}

.okx-pullup {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	/*transition: all 100ms ease-out 0s;*/
	-webkit-transform: translate3d(0,0,0);
	        transform: translate3d(0,0,0);
}

.okx-puller {
	min-height: 30px;
	min-height: var(--okx-puller-height);
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	margin: 0 auto;
	-webkit-flex-direction: column;
	        flex-direction: column;
	padding-top: 18px;
}

.okx-pullup-content {
	position: absolute;
	left: 0;
	right: 0;
	top: 35px;
	bottom: 0;
	overflow-y: auto;
}

.okx-pullup-content-top-75 {
	top: 75px;
}

.okx-pullup-content-top-110 {
	top: 110px;
}

.notch {
	width: 50px;
	height: 6px;
	background: rgb(var(--ion-color-gray-rgb), .6);
	border-radius: 3px;
	-webkit-align-self: center;
	        align-self: center;
	position: absolute;
	top: 12px;
}

.gm-holder {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
  }
  
  .map-holder {
	position: relative;
	height: 100%;
  }
  
  .map {
	width: 100%;
  height: 100%;
  }
  
  .gm-style .map-location-holder {
	z-index: 9999999;
  }
  
  .map-location-pullup {
	background-color: var(--okx-background-color);
	border-top-left-radius: var(--ion-card-radius);
	border-top-right-radius: var(--ion-card-radius);
  }
  
  .map-location-holder {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	background-color: var(--okx-background-color);
	border-radius: 5px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	z-index: 1;
  }
  
  .map-location-holder {
	border-radius: var(--ion-card-radius);
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
  }
  .map-location-holder ion-content{
	--background:var(--okx-background-color)
  }
  .map-location-content {
	-webkit-flex: 1 1 100%;
	        flex: 1 1 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: stretch;
	        justify-content: stretch;
	-webkit-align-items: center;
	        align-items: center;
	padding-top: 0;
  }
  .route-locations .strong-text{
	font-weight: bold;
  }
  .map-location-content > * {
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
  }
  
  .map-location-content > .map-location-nav {
	-webkit-flex: 0 0;
	        flex: 0 0;
  }
  
  .map-location-content > .map-location-nav ion-button {
	font-size: 18px;
	--padding-start: 0;
	--padding-end: 0;
  }
  
  .map-location-content .swiper-slide {
	display: block;
	text-align: left;
  }
  
  .map-location-content ion-content {
	--padding-start: 10px;
	--padding-end: 10px;
	--padding-top: 0;
  }
  
  .map-location-content ion-content ion-slide > div {
	padding-bottom: 20px;
	margin-left: 2px;
  }
  
  .map-location-content .sectiontitle {
	margin:  0;
	letter-spacing: 0.024em;
  }
  
  /* .map-location-content .title {
	margin: 40px 0 0 0 !important;
  } */
  .map-text{
	display: block;
  }
  .web .map-location-content .title {
	margin-top: 0px !important;
  }
  .location-info-segment ion-text:last-child{
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
  }
  .location-info > div {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
  }
  
  .location-times {
	white-space: pre-line;
  }
  
  @supports (-webkit-overflow-scrolling: touch) {
	.map-location-content ion-content {
	  position: absolute;
	  left: 50px;
	  right: 50px;
	  top: 0;
	  bottom: 0;
	  width: auto;
	}
	.map-location-content > .map-location-nav {
	  position: absolute;
	  width: 50px;
	}
	.map-location-content > .map-location-nav:first-child {
	  left: 0;
	}
	.map-location-content > .map-location-nav:last-child {
	  right: 0;
	}
  }
  
  .gm-style {
	height: 50% !important;
  }
  
  .web .gm-style {
	height: 100% !important;
  }

.web .static.route-locations {
	display: none;
}

.web ion-header.route-locations + ion-toolbar.dynamic-header,
.web ion-header.route-locations + .content-spinner + ion-toolbar.dynamic-header {
	position: absolute;
	left: 10px;
	top: 84px !important;
	width: 60px !important;
}

.web .route-locations .map {
	width: calc( 100% - 350px ) !important;
}

.web .route-locations .okx-pullup.map-location-pullup {
	top: 0 !important;
	bottom: 0 !important;
	width: 350px;
	left: auto;
	right: 0;
	margin-left: 0;
	border-radius: 0;
	box-shadow: 0 0 32px rgba(0,0,0,0.25);
}

.web .route-locations .okx-pullup-content {
	top: 120px;
	bottom: 32px !important;
}

.web .map-location-content {
	-webkit-align-items: flex-start;
	        align-items: flex-start;
}

.web .map-location-nav {
	margin-top: 64px;
	padding: 0 5px;
}

.web .okx-puller {
	display: none;
}

@media screen and (max-width: 640px) {
	.web .route-locations .map {
		width: 100% !important;
		height: 70% !important;
	}
	.web .route-locations .okx-pullup.map-location-pullup {
		top: 70% !important;
		width: 100%;
		left: 0;
	}
	.web .route-locations .okx-pullup-content {
		top: 32px;
		bottom: 32px !important;
	}
	.web .map-location-content {
		-webkit-align-items: center;
		        align-items: center;
	}
	.web .map-location-nav {
		margin-top: 0;
	}
}

ion-grid.no-padding.social ion-col {
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
}

/* ion-grid.social ion-col:nth-child(2n-1) {
	padding-right: 10px;
} */

/* ion-grid.social ion-col:nth-child(2n) {
	padding-left: 10px;
} */

.square {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.route-social .square {
  background-color: var(--ion-color-secondary);
  display: -webkit-flex;
  display: flex;
  position: relative;
  border-radius: var(--okx-box-radius);
  padding: 10px;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 10px;
}

.soc-icon ion-icon {
  height: 20px;
  width: 20px;
}

.square:after {
  content: "";
  display: block;
  padding-bottom: 44px;
}

/* .content {
    position: absolute;
    display: flex;
    flex-flow: column;
    background-color: var(--ion-color-light);
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 15px;
} */

.content {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  /* flex-flow: column; */
  background-color: var(--ion-color-secondary);
  /* align-items: center;
    justify-content: center; */
  width: 100%;
  height: 44px;
  border: 1px solid var(--ion-color-secondary);
  border-radius: 1px;
}

.soc-icon {
  display: -webkit-flex;
  display: flex;
  border-radius: 50%;
  width: 30%;
  height: auto;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 30px;
}

.soc-label {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  /* padding: 8px 0 0 8px; */
}

.soc-label.small-text {
  color: var(--ion-color-white);
  font-size: 14px;
}


.web .route-social.ion-color-white {
  --background: transparent none;
  --ion-color-base: transparent;
}

.web .square {
  cursor: pointer;
}

.web .route-social .ion-padding .frm-wrapper {
  position: absolute;
  left: 32px;
  right: 32px;
  top: 120px;
  bottom: 64px;
  overflow-y: auto;
  --ion-color-success: var(--ion-color-primary);
}

.okx-password-wrapper {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	width: 100%;
}

.okx-password-wrapper .okx-password-holder {
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
}

.okx-password-wrapper ion-button {
	-webkit-flex: 0 0 auto;
	        flex: 0 0 auto;
}

.new-user {
	margin-right: 5px;
	color: var(--ion-color-secondary);
	text-transform: capitalize;
	font-family: primary-bold !important;
}

.web #main > ion-content.route-login > .ion-padding > .absolute-content {
	overflow-y: auto;
}

.login-input-field ion-input{
  font-family: var(--ion-default-font-bold) !important;
}
.toggle {
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.route-register .box-holder {
	margin: 0;
	border: none;
	padding: 0;
}

/* .route-register  .box-holder ion-list{
	border: 1px solid rgba(var(--ion-color-light-rgb), 1);
	padding: 10px;
	border-radius: 5px;
  } */
.web #main>ion-content.route-register>.ion-padding {
	height: 85%;
}

.web #main>ion-content.route-register>.ion-padding .absolute-content {
	overflow-y: auto;
}


.route-register .data-picker-input {
	background-color: transparent;
	color: var(--ion-color-primary);
}

.route-register .data-picker-input::-webkit-input-placeholder {
	opacity: 0.5;
}

.route-register .data-picker-input:-ms-input-placeholder {
	opacity: 0.5;
}

.route-register .data-picker-input::placeholder {
	opacity: 0.5;
}

.route-register .date-picker-wrapper ion-label {
	font-size: var(--ion-font-size);
	margin-bottom: 10px !important;
}

.route-register ion-checkbox,
.route-contact-details ion-checkbox,
.route-account ion-checkbox {
	margin-right: 20px;
	--border-radius: 2px;
	--border-color: var(--ion-color-primary);
	--border-width: 1px;
}

.register-terms-wrapper {
	display: grid;
	grid-auto-flow: column;
	-webkit-align-items: center;
	        align-items: center;
}

.route-register .normal-text {
	font-size: 15px !important;
}
/* .reset-password-background { */
	/* background: url(../../assets/images/login-01.png) transparent no-repeat 50% 100%; */
	/* background-size: 100% auto; */
/* } */

.web #main > ion-content.route-reset-password > .ion-padding {
	position: relative;
	/* width: 50%;
	max-width: 400px;
	min-width: 280px;
	margin-top: 60px;
	margin-left: auto;
	margin-right: auto; */
	background-color: #fff;
	border-radius: var(--okx-box-radius);
	box-shadow: 0 0 32px rgba(0,0,0,0.25);
	padding: 48px 32px 5px;
	height: 55%;
	top: 50%;
	-webkit-transform: translate(0%, -50%);
	        transform: translate(0%, -50%);
}

#main > ion-content.route-reset-password > .ion-padding ion-list > ion-item {
	--border-color: rgba(var(--ion-color-light-rgb), 1);
}

.web .route-reset-password .ion-padding .absolute-content {
	padding: 48px 32px 40px;
}


@media screen and (min-width: 1441px) {
	.web #main > ion-content.route-reset-password > .ion-padding {
		height: 45%;
	}
}
.web .route-terms {
	--background: transparent none;
	--ion-color-white: transparent;
}

.web #main > ion-content.route-terms > .ion-padding {
	position: fixed;
	/* left: 50%;
	top: 50px;
	bottom: 50px;
	width: 560px; */
	margin-left: 0;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	height: 75%;
	background-color: #fff;
	border-radius: var(--okx-box-radius);
	box-shadow: 0 0 32px rgba(0,0,0,0.25);
}

.web .route-terms .ion-padding > div {
	overflow-y: auto;
	max-height: 100%;
	padding-right: 8px;
}

.web .route-privacy {
	--background: transparent none;
	--ion-color-white: transparent;
}

.web #main > ion-content.route-privacy > .ion-padding {
	position: fixed;
	/* left: 50%;
	top: 50px;
	bottom: 50px;
	width: 560px; */
	margin-left: 0;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	height: 75%;
	background-color: #fff;
	border-radius: var(--okx-box-radius);
	box-shadow: 0 0 32px rgba(0,0,0,0.25);
}

.web .route-privacy .ion-padding > div {
	overflow-y: auto;
	max-height: 100%;
	padding-right: 8px;
}


@media screen and (min-width: 1441px) {
	.web #main > ion-content.route-privacy > .ion-padding {
		height: 65%;
	}
}
.web .route-faq {
	--background: transparent none;
	--ion-color-white: transparent;
}

.web #main > ion-content.route-faq > .ion-padding {
	position: fixed;
	/* left: 50%;
	top: 50px;
	bottom: 50px;
	width: 560px; */
	margin-left: 0;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	height: 75%;
	border-radius: var(--okx-box-radius);
	box-shadow: 0 0 32px rgba(0,0,0,0.25);
}

.web .route-faq .ion-padding > div {
	overflow-y: auto;
	max-height: 100%;
	padding-right: 8px;
}

@media screen and (min-width: 1441px) {
	.web #main > ion-content.route-faq > .ion-padding {
		height: 65%;
	}
}
:root {
	--clickup-initial-height: 50px;
	--clickup-open-height: 50%;
}

.pill {
	/* border-radius: var(--okx-small-radius); */
	display: inline-block;
	border: 1px solid var(--ion-color-primary);
	font-size: var(--okx-small-text-font-size);
	/* padding: 5px; */
	padding: 2px 6px;
	margin: 0 5px 5px 0;
	text-transform: uppercase;
}

.pill-action {
	cursor: pointer;
}

/* behavior */

.item-details-image {
	position: absolute;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	/* next line is portrait-only */
	height: 100vw;
	background: transparent none no-repeat 50% 0;
	background-size: cover;
}

.item-details-card-header h2,
.item-details-card-header h3,
.item-details-card-header h4 {
	margin-top: 0;
}

.item-details-card .okx-puller {
	border-bottom: 6px solid var(--ion-color-light);
}

.item-details-actions {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
	z-index: 10;
	background-color: var(--okx-background-color);
}



/* end behavior */

/* content reset */

ion-button.item-details-add-to-order {
	margin-top: 0;
	margin-bottom: 0;
	--background: var(--ion-color-secondary);
	--background-activated: rgba(var(--ion-color-tertiary-rgb), 0.2);
	color: var(--ion-color-contrast-secondary);
}

ion-button.item-details-add-to-order.greyed {
	--background: var(--ion-color-gray-tint);
	/* --background: var(--ion-color-secondary); */
	color: var(--ion-color-primary);
}

/* end content reset */

/* design */



.item-details-card {
	background-color: var(--okx-background-color);
	border-top-left-radius: var(--ion-card-radius);
	border-top-right-radius: var(--ion-card-radius);
}

.item-details-card-header {
	padding: 10px var(--ion-padding) 10px;
	z-index: 1;
	background: var(--ion-color-white);
}

.item-details-card-header>* {
	font-size: var(--ion-font-size);
}

.item-detals-background {
	background-color: var(--okx-background-color);
}

.item-details-card-content .sectiontitle {
	margin: 0;
}

/* .item-details-card-description,
  .item-details-card-description p,
  .item-details-card-description p span {
	font-size: 12px !important;
	color: var(--ion-color-primary) !important;
  } */
.image-placeholder {
	background: var(--ion-color-gray) url(/static/media/logo-main.193dd6f3.svg) no-repeat 50% 50%/90% 50%;
	height: 350px;
}

span.item-details-card-description {
	display: inline-block;
}

.item-details-card-price {
	font-size: var(--okx-subtitle-font-size);
	font-weight: bold;
}

.item-details-card-content {
	padding: 0 var(--ion-padding);
	margin-bottom: 44px;
}

.web .item-details-card-content {
	margin-bottom: 0;
}

.item-details-actions {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 25px;
	padding: 20px var(--ion-padding);
}

.item-details-actions-quantity {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.item-details-remove,
.item-details-add {
	-webkit-flex: 0 0 40px;
	        flex: 0 0 40px;
	height: 40px;
	--padding-start: 0;
	--padding-end: 0;
	--border-width: 1px;
	--border-style: solid;
	--border-color: var(--ion-color-light);
	--background: var(--ion-color-white);
	--background-hover: var(--ion-color-light);
	--color: var(--ion-color-primary);
}

.item-details-quantity {
	-webkit-flex: 0 0 75px;
	        flex: 0 0 75px;
	text-align: center;
}

.item-details-background {
	background: var(--ion-color-gray) url(/static/media/logo-main.193dd6f3.svg) no-repeat 50% 50%/90% 50%;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	/* next line is portrait-only */
	opacity: 0.7;
	height: 100vw;
}

.item-details-product-label {
	line-height: normal;
}

/* .route-item-details .okx-pullup-content {
	  padding-bottom: 20px;
  }
  
  .route-item-details .native-textarea.sc-ion-textarea-md,
  .route-item-details .native-textarea.sc-ion-textarea-ios {
	  transform: translate3d(0,0,0) !important;
	  -webkit-transform: translate3d(0,0,0) !important;
  } */


/* end design */

.web .okx-pullup {
	top: 120px !important;
	bottom: 50px !important;
	-webkit-transform: none !important;
	        transform: none !important;
	left: 50%;
	right: auto;
	width: var(--okx-bsmodal-width-lg);
	margin-left: -200px;
	border-radius: var(--okx-box-radius);
}

.web .notch {
	display: none;
}

.web .okx-pullup-content {
	bottom: 140px !important;
}

.web .item-details-card-content {
	overflow-y: auto;
	padding: 0 10px 0 0;
}

.item-details-card-content .pill {
	padding: 2px 10px !important;
}


.route-item-details {
	overflow: hidden;
}

/* new item details */

.item-details-header {
	display: -webkit-flex;
	display: flex;
	padding: 35px var(--ion-padding) 15px;
	position: absolute !important;
	top: 0;
	z-index: 5;
	width: 100%;
}

.item-details-price {
	padding: 0;
}

.item-details-price .normal-text {
	font-size: 16px;

}

.item-details-header.active {
	background-color: var(--okx-background-color);
}

.item-details-header ion-button {
	margin: 0;
	height: 40px;
	width: 40px;
	--border-radius: 50%
}

.item-details-description {
	-webkit-flex: 1 1;
	        flex: 1 1;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	padding: 10px;
}

.item-details-info .item-details-name {
	padding: 20px var(--ion-padding) 0;
}

.item-details-main-wrapper {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	height: 100vh;
	background-color: var(--okx-background-color);

}

.sub-item ion-item {
	-webkit-flex: 0 0 220px;
	        flex: 0 0 220px;
}

.special-instructions-wrapper {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	cursor: pointer;
	margin-bottom: 10px;
}

.special-instructions-modal-button {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: flex-end;
	        align-items: flex-end;
	-webkit-flex: 1 1;
	        flex: 1 1;
}

.special-instructions-modal-button ion-button {
	width: 100%;
}

.special-instructions-modal .modal-classic-content {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	padding-top: 40px !important;
}

.web .special-instructions-modal .modal-classic-content {
	-webkit-justify-content: flex-start !important;
	        justify-content: flex-start !important;
}

.special-instructions-wrapper ion-icon {
	margin-left: 10px;
}

.special-instructions-wrapper ion-button {
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	margin: 0;
}

.add-instructions-button {
	--padding-start: 0 !important;
	--padding-end: 0 !important;
}

.special-instructions-modal .modal-wrapper {
	border-radius: 0;
}

.web .special-instructions-modal .modal-wrapper {
	border-radius: var(--border-radius);
}

.item-details-img {
	height: 350px;
	width: 100%;
	overflow: hidden;
	position: relative;
}

.item-details-img img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.special-instructions-modal {
	--height: 100% !important;
	--width: 100% !important;
}

.web .special-instructions-modal {
	--height: 80% !important;
	--width: 500px !important;
}

.special-instructions-wrapper .normal-text {
	white-space: pre-wrap;
}

/* @media screen and (max-width: 330px) {
	.item-details-card-content .item-details-card-list {
	  padding: 0 10px;
	}
	.sub-item ion-item {
	  flex: 0 0 180px;
	}
	.item-details-price {
	  padding: 0 10px;
	}
  } */

.item-details-card {
	background-color: var(--okx-background-color);
	border-top-left-radius: var(--ion-card-radius);
	border-top-right-radius: var(--ion-card-radius);
}

.item-details-group-wrapper {
	border: 1px solid var(--ion-color-gray);
	border-radius: var(--okx-box-radius);
	overflow: hidden;
	margin-bottom: 20px;
}

.item-details-group-wrapper ion-item {
	background-color: var(--ion-color-white);
	padding: 5px 20px;
	border-bottom: 1px solid var(--ion-color-gray);
}

.web .item-details-group-wrapper ion-item {
	padding: 0 10px;
}

.item-details-group-wrapper ion-item:last-of-type {
	border-bottom: 0;
}

.click-and-collect-timepicker-wrapper {
	margin: 0 var(--ion-padding);
	background-color: transparent !important;
	padding: 0 !important;
	overflow: hidden;
}

.item-modifier-wrapper {
	padding-left: 20px;
	position: relative;
}

.item-modifier-wrapper>div {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}

ion-modal.special-instructions-modal .modal-wrapper {
	border-radius: 0 !important;
}

.web ion-modal.special-instructions-modal .modal-wrapper {
	border-radius: var(--okx-box-radius) !important;
}

.special-instructions-modal .modal-classic-content .title {
	margin-bottom: 20px;
}

.special-instructions-modal>.modal-wrapper>.ion-page .modal-classic-closer{
	top:28px;
}

.special-instructions-modal>.modal-wrapper>.ion-page .modal-classic-closer ion-icon {
	height: 25px;
	width: 25px;
	right: 10px !important;
	top: 10px;
}
.web ion-content.route-delivery-options {
	width: 100%;
	/* height: 100%; */
	height: calc(100vh - 79px);
  	background-image: url(/static/media/refer-a-friend.65079366.jpg);
	background-position: 50% 100%;
	background-size: cover;
	background-repeat: no-repeat;
	overflow-y: hidden;
}

ion-content.route-delivery-options .absolute-content::before {
	content: '';
	display: block;
	height: 100%;
	position: absolute;
	left: 0; top: 0; right: 0; bottom:85%;
	background-image: linear-gradient(rgba(000,000,000,1), rgba(000,000,000,0));
	transform: translate3d(0,0,0);
	-webkit-transform: translate3d(0,0,0);
	z-index: 1;
}

.delivery-options-card {
	margin: 0;
	border-radius: 0px;
	height: 100%;
}

.delivery-options-card ion-card-title {
	font-size: 25px;
	font-weight: bold;
}

.delivery-options-menu {
	/* padding: 0 64px; */
	padding: 0 24px;
}

.delivery-options-menu ion-item {
	--ion-color-contrast: var(--ion-color-white) !important;
	--color: var(--ion-color-contrast);
	--background: var(--ion-color-secondary);
	--min-height: 32px;
	margin-bottom: 20px;
	border-radius: calc(var(--okx-small-radius) -  2px);
	cursor: pointer;
}

.delivery-options-menu ion-label {
	font-weight: normal;
	text-align: center;
}

.delivery-options-menu > ion-list > ion-item > ion-label {
	padding-top: 2px;
	margin-right: 0;
	font-weight: bold !important;
}

.web #main > ion-content.route-delivery-options {
	background-size: cover;
}

.web ion-content.route-delivery-options::after {
	content: '';
	position: absolute;
	left: 0; top: 0; right: 0; bottom: 0;
	backdrop-filter: blur(8px);
	-webkit-backdrop-filter: blur(8px);
	transform: translate3d(0,0,0);
	-webkit-transform: translate3d(0,0,0);
	z-index: -1;
}

.web .delivery-options-card {
	position: absolute;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: space-around;
	        justify-content: space-around;
	padding-top: 32px;
	padding-bottom: 80px;
	margin: 0;
	z-index: 2;
	--background: var(--okx-background-color);
	border-radius: var(--ion-card-radius);
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	box-shadow: 0 0 32px rgba(0,0,0,0.25);
	height: 50%;
	top: 50%;
	left: 50%;
	right: auto;
	width: var(--okx-bsmodal-width-sm);
}
.web ion-content.route-delivery {
	--ion-color-base: transparent;
	--background: transparent;
}
/* work */
ion-content div.restaurant-gradient {
	background-image: url(/static/media/Click&Collect.f1d29dc9.jpg);
	background-repeat: no-repeat;
	/* background-position: 100% 0; */
	background-position: 50% 100%;
	background-size: cover;
}

ion-content div.restaurant-gradient::before {
	content: '';
	display: block;
	height: 80%;
	position: absolute;
	left: 0; top: 0; right: 0; bottom:85%;
	z-index: 3;
	background-image: linear-gradient(rgba(255,255,255,1), rgba(255,255,255,0));
}

/* don't work 
.restaurant-gradient::before {
	background-image: linear-gradient(rgba(255,255,255,1), rgba(255,255,255,0));
}

.restaurant-gradient::before {
	content: '';
	display: block;
	position: absolute;
	left: 0; top: 0; right: 0; bottom: 0;
	z-index: 1;
	background-image: url(../../assets/images/clickAndCollect.png);
	background-repeat: no-repeat;
	background-position: 50% 0;
	background-size: 150% auto;
}
*/

.restaurant-card {
	border-radius: 0px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 3;
	margin: 0;
	background: var(--okx-background-color);
}

.restaurant-card ion-card-content {
	padding: 30px;
	font-size: var(--ion-font-size);
}

.restaurant-card ion-card-content .restaurant-info-title {
	padding: 0 2px;
	font-weight: bold;
}

.restaurant-card ion-card-content .restaurant-info {
	padding: 15px 15px 30px;
	text-align: center;
}

.restaurant-card .flex-col-wrapper > .flex-min {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}

.click-collect-pickers-backdrop {
	position: fixed;
    top: 0;
    background-color: black;
    opacity: 0.3;
    height: 100%;
    width: 100%;
    z-index: 3;
}

.click-collect-dialog {
	position: fixed;
	margin: 0 auto;
	background-color: #fff;
	width: 70%;
	height: 50%;
	border-radius: 10px;
	top: 55%;
	left: 50%;
	-webkit-transform: translate(-50%, -45%);
	        transform: translate(-50%, -45%);
	transition: all .3s ease-out;
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	z-index: 999;
}

.click-collect-dialog.show-up {
	visibility: visible;
	opacity: 1;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	pointer-events: auto;
}

.click-collect-dialog-layout {
	height: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
	-webkit-flex-direction: column;
	        flex-direction: column;
	padding: 5px 13px;
}

.click-collect-dialog.sc-ion-modal-md {
	border-radius: var(--border-radius);
	width: var(--width);
	min-width: var(--min-width);
	max-width: var(--max-width);
	height: var(--height);
	min-height: var(--min-height);
	max-height: var(--max-height);
	border-width: var(--border-width);
	border-style: var(--border-style);
	border-color: var(--border-color);
	background: var(--background);
	box-shadow: var(--box-shadow);
	overflow: var(--overflow);
	z-index: 10;
}

/* .click-collect-dialog.sc-ion-modal-md {
	-webkit-transform: translate3d(0,40px,0);
	transform: translate3d(0,40px,0);
	opacity: .01;
} */

.click-collect-dialog-header {
	-webkit-flex: 0 1 auto;
	        flex: 0 1 auto;
	padding: 10px 5px;
}

.click-collect-dialog-header h3 {
	font-size: 14px;
	font-weight: 700;
	margin: 0;
}

.click-collect-dialog-closer {
	position: absolute;
	top: 0;
	right: 0;
	padding: 10px;
	cursor: pointer;
}

.click-collect-dialog-closer ion-icon {
	font-size: 20px;
}

.click-collect-dialog-content {
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
	padding: 12px;
	overflow-y: auto;
}

.click-collect-dialog-action {
	-webkit-flex: 0 1 auto;
	        flex: 0 1 auto;
}

.click-collect-dialog-action ion-button {
	height: 36px;
}

.click-collect-dialog-content ion-label .sectiontitle {
	margin: 0;
	line-height: 18px ;
}

#main > ion-content.route-click-and-collect .select-picker-label,
#main > ion-content.route-click-and-collect .time-picker-label {
	color: var(--ion-color-primary);
	font-size: 11px;
	font-weight: bold;
	letter-spacing: .054em;
}

#main > ion-content.route-click-and-collect .mbsc-select-input.mbsc-control {
	border-color: var(--ion-color-light);
	display: none;
}

/*
@keyframes popupAnimation {
	0% {

	}

	75% {

	}

	100% {

	}
}
*/

.delivery-option-label {
	position: absolute;
	top: 30px;
	left: 64px;
	right: 64px;
	text-align: center;
	z-index: 3;
}

/* .web #main > ion-content.route-click-and-collect {
	z-index: 15;
} */

.web ion-content.route-click-and-collect {
	--background: url(/static/media/Click&Collect.f1d29dc9.jpg) no-repeat 50% 100% / 100%;
	--ion-color-white: transparent;
} 
/* .web ion-content div.restaurant-gradient,
.web ion-content div.restaurant-gradient::before {
	background-image: none;
	background-color: transparent;
} */

.web .restaurant-card {
	top: 50%;
	bottom: auto;
	left: 50%;
	right: auto;
	position: fixed;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	width: var(--okx-bsmodal-width-lg);
	max-width: var(--okx-bsmodal-width-lg);
	min-width: 280px;
	padding: 150px 64px 64px;
	margin-left: auto;
	margin-right: auto;
	border-radius: var(--okx-box-radius);
	background-color: var(--okx-background-color);
}

.web .restaurant-menu ion-item {
	--color: var(--ion-color-white);
	--background: var(--ion-color-black);
	margin-bottom: 20px;
	border-radius: var(--okx-small-radius);
}

.web .restaurant-card ion-card-content {
	padding: 0;
}

.web .restaurant-card ion-card-content .restaurant-info {
	padding: 15px 0 0;
}

.web .click-collect-dialog {
	width: var(--okx-bsmodal-width-lg);
}

.web .click-collect-dialog-layout {
	padding: 32px 64px 48px;
}

.web .mbsc-select-input.mbsc-control {
	border-color: var(--ion-color-light);
}

.web .click-collect-dialog-header h3 {
	font-size: 22px;
	text-transform: uppercase;
}

.web .mbsc-fr-popup {
	left: 50%;
}

/* .web .content-spinner {
	background-color: transparent;
} */

.web #main > ion-content.route-click-and-collect .delivery-option-label {
	display: none;
}

.web #main > ion-content.route-click-and-collect .delivery-option-label.web-only {
	display: block;
	top: 64px;
}


.click-collect-description {
	font-size: var(--okx-small-text-font-size);
	font-family: primary-regular;
	font-weight: 300;
	padding: 1px 0;
	opacity: 0.5;
  }
  
  .click-collect-small-description{
	font-size: var(--okx-small-text-font-size) !important;
  }
  
  .secondary-description {
	color: var(--ion-color-secondary);
  }
  
  .bold-description {
	font-weight: 600;
	font-size: var(--okx-sectiontitle-font-size);
  }
  .click-collect-button{
	padding:  0 
  }
  .click-collect-button-wrapper {
	padding:  0 var(--ion-padding);
	/* position: absolute; */
	bottom: 0;
	width: 100%;
  }
  
  ion-checkbox {
	margin-left: 0;
	--border-color: var(--ion-color-secondary);
  
  }
  
  .click-collect-wrapper {
	height: calc(100% - 50px);
  }
  
  .click-collect-layout {
	height: calc(100% - 80px);
	border-radius: var(--okx-box-radius);
  }
  
  .click-collect-page {
	height: 100% !important;
  }
  
  
  .web .click-collect-locations,
  .click-collect-locations ion-list {
	height: 52vh !important;
  }
  
  .click-collect-locations,
  .click-collect-locations ion-list {
	height: calc(100% - 100px) !important;
	overflow-y: auto;
  }
  
  .click-collect-items {
	border-bottom: 1px solid var(--ion-color-light);
  }
  
  .click-collect-title-description{
	  font-weight: bold;
	  font-size: inherit !important;
  }

  .click-and-collect-timepicker-wrapper{
	margin: 0 var(--ion-padding);
	background-color: transparent !important;
	padding: 0!important;
	overflow: hidden;
  }
.address-checking-box {
	min-height: 80px;
}

.delivery-address-bg {
	background-image: url(/static/media/Click&Collect.f1d29dc9.jpg);
	background-repeat: no-repeat;
	background-position: 50% 100%;
	background-size: cover;
}

.delivery-address-bg::before {
	content: attr(title);
	position: absolute;
	font-size: var(--okx-title-font-size);
	font-weight: bold;
	text-align: center;
	padding-top: 20px;
	display: block;
	left: 0; bottom: 0; right: 0; top: 0;
	height: 50%;
	z-index: 2;
	background-image: linear-gradient(rgba(255,255,255,1), rgba(255,255,255,0));
}

.route-delivery-address-check .restaurant-card {
	top: 0;
}

.route-delivery-address-check .restaurant-card ion-card-content {
	height: 100%;
}

/* #main > ion-content.route-delivery-address-check .restaurant-card {
	position: relative;
	height: calc(100vh - 114px);
	border-radius: 0;
} */

/* #main > ion-content.route-delivery-address-check .restaurant-card ion-card-content {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 0; left: 0; right: 0; bottom: 0;
	z-index: -1;
} */

/* #main > ion-content.route-delivery-address-check .no-padding .restaurant-card ion-card-content .button-holder {
	flex: 1 1 auto;
	display: flex;
	align-items: flex-end;
}

#main > ion-content.route-delivery-address-check .no-padding .restaurant-card ion-card-content .button-holder ion-button {
	flex: 1 1 auto;
}


#main > ion-content.route-delivery-address-check .no-padding .restaurant-card ion-card-content .underlined {
	font-size: var(--okx-small-text-font-size);
}

#main > ion-content.route-delivery-address-check .no-padding ion-text:last-child > span {
	letter-spacing: .044em;
} */

.web .route-delivery-address-check .restaurant-card {
	top: 50%;
}

.web .delivery-address-bg::before {
	content: '';
}

.web #main > ion-content.route-delivery-address-check .delivery-option-label {
	position: absolute;
	top: 64px;
	left: 0;
	right: 0;
	text-align: center;
}

/* .delivery-address-add > div.scrollable-y > ion-list > ion-item > ion-input { */
  /* direction: LTR;
  unicode-bidi: bidi-override; */
  /* writing-mode: vertical-rl;
  text-orientation: upright; */
/* } */

/* .delivery-address-add > div.scrollable-y > ion-list > ion-item > ion-input > input.native-input.sc-ion-input-ios:invalid { */
  /* direction: ltr;
  unicode-bidi: bidi-override; */
  /* writing-mode: vertical-rl;
  text-orientation: upright; */
/* } */

ion-content.route-delivery-address-add ion-textarea > div > textarea.native-textarea.sc-ion-textarea-ios {
  padding: 0px;
  padding-top: 7px;
}

ion-content.route-delivery-address-add ion-item {
  --border-color: var(--ion-color-light);
}

.web ion-content.route-delivery-address-add {
	--ion-color-base: transparent;
	--background: transparent;
}
.web ion-content.route-delivery-time {
	--ion-color-base: transparent;
	--background: transparent;
}

.map-inline > div:first-child {
	height: 100%;
}

ion-modal.modal-classic.pup-modal {
	--width: 80%;
	--height: 80%;
}

.pup-map-wrapper {
	position: relative;
	margin: 20px auto;
	width: 80%;
	min-width: 200px;
}

.pup-map-wrapper::after {
	content: '';
	display: block;
	padding-top: 100%;
}

.code-checking-box {
	min-height: 80px;
}

.web .route-cards .ion-padding ion-button:last-child {
	margin-bottom: 0;
  }
  
  .route-cards .card-wrapper ion-text,
  .route-cards .card-wrapper .small-text {
	display: block;
  }
  .route-cards .card-wrapper .single-item {
	margin-bottom: 5px;
  }
  
  .cards-list-card {
	margin: 0 0.5px 15px 0.5px;
  }
  
  .cards-list-card-image {
	height: 50px;
	width: 50px;
	background-color: var(--ion-color-white);
	border-radius: 5px;
	margin-right: 15px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
  }
  .cards-list-card-image ion-icon {
	height: 25px;
	width: 25px;
	opacity: 0.8;
  }
  
.payment-card-input {
  border: 0;
  outline: none;
  -webkit-flex: 1 1;
          flex: 1 1;
  padding-left: 0;
	background-color: transparent;
}

.scrollable-y.add-card-scroll {
	overflow: unset;
	overflow-y: unset;
}

.web-stripe-input {
  min-height: 48px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 1px solid rgba(0,0,0,0.13);
  border-bottom: 1px solid var(--ion-item-border-color,var(--ion-border-color,var(--ion-color-step-150,rgba(0,0,0,0.13))));
}

.web-stripe-input > .__PrivateStripeElement {
  width: 100%;
}

ion-list .card-list ion-item {
  padding-left: 7px;
}

.web-stripe-input-active {
  border-bottom: 2px solid var(--ion-color-primary);
}

.card-add-wrapper .data-picker-input {
  border-bottom: 1px solid;
  border-color: rgba(0,0,0,0.13);
  border-color: var(--ion-item-border-color,var(--ion-border-color,var(--ion-color-step-150,rgba(0,0,0,0.13))));
}

.cards .ion-text-wrap > ion-note {
	color: var(--ion-color-primary);
	font-size: 10px;
}

.route-card-add .ion-padding .absolute-content ion-list ion-item .sectiontitle {
	margin: 4px 0;
}

.cards ion-item {
  border-bottom: 1px solid var(--ion-color-light);
}

.cards + ion-button.link {
  font-size: var(--okx-small-text-font-size);
}

.web .scrollable-y.add-card-scroll {
	overflow: hidden;
	overflow-y: auto;
}

.web ion-content.route-card-add {
	--background: transparent none;
	--ion-color-white: transparent;
}

.web .route-card-add .ion-padding {
	position: absolute;
	left: 50%;
	top: 30px;
	bottom: 50px;
	width: var(--okx-bsmodal-width-lg);
	margin-left: -200px;
	background-color: #fff;
	border-radius: var(--okx-box-radius);
	box-shadow: 0 0 32px rgba(0,0,0,0.25);
}

.web .route-card-add .ion-padding .absolute-content {
	padding: 32px;
}

.web .route-card-add .ion-padding .absolute-content .scrollable-y/*,
.web .route-card-add .ion-padding .absolute-content .flex-min*/ {
	padding: 0 32px;
}

.web .route-card-add .ion-padding ion-button:first-child {
	display: none;
}

.web .route-card-add .ion-padding ion-button:last-child {
	margin-bottom: 0;
}

.add-payment-card{
	background-color: var(--okx-background-color);
}

.payment-card-details{
	font-family: var(--ion-default-font-bold);
}



.apply-points-spacer {
	margin: 32px 0;
	height: 170px;
}

.route-apply-points .ion-padding .incrementer-quantity-solo {
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.route-apply-points .ion-padding .incrementer-quantity-solo .incrementer-quantity-value {
	font-size: var(--okx-big-label-font-size);
}

.route-apply-points .ion-padding .incrementer-decrease,
.route-apply-points .ion-padding .incrementer-incease {
	--border-color: var(--ion-color-secondary);
}

.web .route-apply-points {
	--background: transparent none;
	--ion-color-white: transparent;
}

.web .route-apply-points .ion-padding {
	position: absolute;
	left: 50%;
	top: 30px;
	bottom: 50px;
	width: 400px;
	margin-left: -200px;
	background-color: #fff;
	border-radius: var(--okx-box-radius);
	box-shadow: 0 0 32px rgba(0,0,0,0.25);
}

.web .route-apply-points .ion-padding .absolute-content {
	padding: 32px 32px 16px;
}

.web .route-apply-points .ion-padding .absolute-content .scrollable-y/*,
.web .route-apply-points .ion-padding .absolute-content .flex-min*/ {
	padding: 0 32px;
}

.web ion-modal.modal-classic.apply-points-modal .modal-classic-closer {
	font-size: 20px;
	padding: 11px;
}

.web ion-modal.modal-classic.apply-points-modal .incrementer-decrease,
.web ion-modal.modal-classic.apply-points-modal .incrementer-incease {
	--border-color: var(--ion-color-secondary);
}
.validate-content {
  margin-top: 50px;
}

ion-item.contact-number {
  --border-color: var(--ion-color-gray);
}

ion-item.contact-number > ion-input {
  --padding-bottom: 6px !important;
  font-size: var(--ion-font-size);
}

.link.link-add-items {
  background: var(--ion-color-primary);
  padding-left: var(--okx-box-padding-left);
  padding-right: var(--okx-box-padding-right);
  border-radius: 30px;
}

.checkout > .righted > ion-button.link.link-centered {
  background: black;
  color: white;
  border-radius: 20px;
  min-width: 110px;
}

.checkout + .flex-min > ion-button.link {
  text-decoration: underline;
}

.checkout + .flex-min > ion-button.link + ion-button > ion-icon {
  font-size: 15px;
}

.apply-points-modal > .modal-wrapper {
  width: 100%;
  height: 100vh;
  border-radius: 0;
  overflow: hidden;
}

.apply-points-modal .incrementer {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0 10px;
}

.apply-points-modal .incrementer .incrementer-quantity-solo {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  font-weight: bold;
}
.apply-points-modal .wrap-small-text {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
ion-app:not(.web){
  background-color: var(--okx-background-color);
}
ion-app:not(.web) .allergen-modal > .modal-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 0;
}

ion-app:not(.web) .allergen-modal > .modal-wrapper > .ion-page {
  min-width: 100%;
  overflow: auto;
}

ion-app:not(.web) .allergen-modal > .modal-wrapper > .ion-page > .modal-classic-wrapper {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}

ion-app:not(.web) .allergen-modal > .modal-wrapper > .ion-page .sectiontitle {
  font-size: 11px;
  letter-spacing: 0.044em;
}

.web .apply-points-modal > .modal-wrapper {
  width: var(--okx-sidebar-width);
}

.web ion-modal.modal-classic.allergen-modal .modal-classic-closer {
  font-size: 20px;
  padding: 11px;
}

.contact-number-label {
  padding-top: 3px;
}

.web .route-order-summary .ion-padding .absolute-content{
  padding: 0;
}

.web .route-order-summary .ion-padding .absolute-content .scrollable-y{
  padding:  var(--ion-padding);
}

.web .route-order-summary .checkout {
  margin-bottom: 20px;
}

.web .route-order-summary .ion-padding ion-button:last-child {
  margin-bottom: 0;
}

.web .route-order-summary .ion-padding ion-button.add-items-btn,
.web .route-order-summary .ion-padding ion-button.redeem-points-btn {
  margin: 10px 0 !important;
}

.web .route-order-summary .ion-padding ion-button.redeem-points-btn.disabled,
.web .route-order .no-padding ion-button.redeem-points-btn.disabled {
  --ion-color-dark: gray;
}

.contact-number-icon {
  position: absolute;
  right: 0;
  bottom: 2px;
  font-size: 15px;
  cursor: pointer;
  border-radius: 50%;
  padding: 7px;
  transition: 0.2s ease-in-out;
}

.web .contact-number-icon {
  bottom: 4px;
}

.web .contact-number-icon:hover {
  background: rgba(0, 0, 0, 0.13);
}

ion-button.checkout-btn.greyed {
  color: var(--ion-color-secondary);
}
ion-grid.order-content-wrapper, ion-grid.order-content-wrapper ion-col{
  padding: 0 !important;
}
.table-number-modal ion-item {
  --border-style:none;
}
.table-number-modal .modal-wrapper{
  height: 250px;
}
.table-number-modal input{
  text-align: center;
}
.table-number-modal .modal-wrapper .modal-classic-content .subtitle{
  color: var(--ion-color-primary);
  text-align: center;
}
.route-order-summary .amount-values, .route-order-summary .amount-values *,
.route-order .amount-values, .route-order .amount-values *,
.route-order-completed .amount-values, .route-order-completed .amount-values *{
  color: var(--ion-color-secondary) !important;
  border-color: var(--ion-color-secondary) !important;
  font-weight: bold !important;
}

.order-intro-title {
  display: none;
}
.web .order-intro-title {
  display: block;
}
.order-header-content-wrapper {
  margin: 0 var(--ion-padding);
}
.order-header-content-wrapper .subtitle{
  margin-bottom: var(--okx-title-margin);
}
.web .order-header-content-wrapper {
  margin: 0;
}
.order-summary-header-wrapper {
  display: -webkit-flex;
  display: flex;
  background-color: var(--ion-color-white);
  border: 1px solid var(--ion-color-light);
  border-radius: var(--okx-box-radius);

}
.order-summary-header-wrapper > div {
  padding:  10px 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
}
.web .order-summary-header-wrapper > div{
  padding: 5px 15px;
}
.order-summary-header-wrapper >div:nth-of-type(2){
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex: unset;
          flex: unset;
  border-left: 1px solid var(--ion-color-light);
}

.order-summary-items-wrapper{
  height: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 0px !important;

}
.add-items-button-wrapper{
  position: absolute;
  top: -40px;
  right: 0
}
.web .order-summary-wrapper .flex-min{
  margin: 0 var(--ion-padding);
}
.basket-item-wrapper ion-row:not(:first-child){
  padding-left:30px;
}

.order-summary-additional-button{
  --border-color:transparent !important;
  background-color: var(--ion-color-white);
  --border-radius:0;
  margin-bottom: 10px;
}
.order-summary-additional-button > ion-text{
  -webkit-flex: 1 1;
          flex: 1 1;
  text-align: left;
}


.allergens-wrapper ion-button{
    font-weight: 900 !important;
}

.allergens-wrapper .strong-text{
    margin-bottom: 10px;
    display: block;
}
.web .allergens-wrapper{
    padding: 20px !important;
}
.route-apply-loyalty .swiper-slide {
  text-align: left;
}

.route-apply-loyalty .incrementer-quantity-solo {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.route-apply-loyalty .incrementer-quantity-value {
  font-size: 46px;
  color: var(--ion-color-primary) !important;
}
.route-apply-loyalty .incrementer-decrease,
.route-apply-loyalty .incrementer-incease {
  border: 1px solid var(--ion-color-secondary);
  border-radius: 4px;
}
.route-apply-loyalty .incrementer-quantity-unit {
  font-family: primary-bold;
}


ion-modal.modal-classic.apply-voucher-modal {
	--width: 80%;
	--height: 45%;
	--border-radius: 10px;
  }
  
  .web ion-modal.modal-classic.apply-voucher-modal {
	--width: 500px;
	--height: 45%;
  }
  

  ion-modal.modal-classic.apply-voucher-modal .modal-classic-wrapper {
	padding: 0;
  }
  
  ion-modal.modal-classic.apply-voucher-modal .modal-classic-header {
	background-color: var(--ion-color-secondary);
	padding-left: 20px;
  }
  
  ion-modal.modal-classic.apply-voucher-modal .modal-classic-header h3 {
	color: var(--ion-color-white);
	text-transform: uppercase;
  }
  
  ion-modal.modal-classic.apply-voucher-modal .modal-classic-closer {
	color: var(--ion-color-white);
  }
  
  ion-modal.modal-classic.apply-voucher-modal .modal-classic-content {
	padding: 15px 15px;
  }
  
  ion-modal.modal-classic.apply-voucher-modal .modal-classic-content > .flex-row-wrapper {
	height: 100%;
  }
  
  .apply-voucher-modal.voucher-modal-content {
	padding: 0 30px;
  }
  
  ion-button.box-button-color {
	--background: var(--ion-color-gray-tint);
  }
  
  ion-button.box-button-color-tertiary {
	--background: var(--ion-color-tertiary);
  }
  
  .apply-voucher {
	border: 5px solid var(--ion-color-secondary);
  }
  .apply-voucher-box,
  .apply-voucher {
	height: 150px;
	margin-right: 2px;
  }
  .apply-voucher-box img,
  .apply-voucher img {
	height: 100%;
	width: 100%;
	object-fit: contain;
  }
  .web .apply-voucher,
  .web .apply-voucher-box {
	cursor: pointer;
	margin: 20px 0 0 !important;
  }
  .apply-voucher-box .voucher-card-wrapper svg {
	fill: var(--ion-color-secondary);
  }
  .voucher-svg {
	height: 100%;
	width: 100%;
  }
  
  .apply-voucher-box,
  .apply-voucher {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	margin-bottom: 20px;
  }
  .apply-voucher-box .voucher-card-content,
  .apply-voucher .voucher-card-content {
	width: auto;
  }
  .apply-voucher .voucher-card-wrapper {
	border-style: solid;
	border-width: 5px;
	border-color: #1770ff;
	width: 100%;
	height: 100%;
  }
  .apply-voucher-box .voucher-card-wrapper {
	width: 100%;
	height: 100%;
  }
  .voucher-icon {
	padding-left: inherit;
  }
  .voucher-card-content {
	display: -webkit-inline-box;
  }
  .vaucher-list {
	position: relative;
  }
  
  .redeem-voucher-field-wrapper {
	display: -webkit-flex;
	display: flex;
	margin-bottom: 30px;
	-webkit-align-items: flex-end;
	        align-items: flex-end;
  }
  .redeem-voucher-field-wrapper >div {
	-webkit-flex: 1 1;
	        flex: 1 1;
	margin-right: 10px;
	margin-bottom: 0;
  }
  .redeem-voucher-field-wrapper ion-button{
	margin: 0;
  }
  
ion-button.link.link-centered {
	margin: 0 auto;
}


.web ion-content.route-checkout {
	--background: transparent;
	--ion-color-white: transparent;
}

.web .route-checkout .ion-padding {
	position: absolute;
	left: 50%;
	top: 30px;
	bottom: 50px;
	width: var(--okx-bsmodal-width-lg);
	margin-left: -200px;
	background-color: #fff;
	border-radius: var(--okx-box-radius);
	box-shadow: 0 0 32px rgba(0,0,0,0.25);
}

.web .route-checkout .ion-padding .absolute-content {
	padding: 32px 32px 16px;
}

.web .route-checkout .ion-padding .absolute-content .scrollable-y/*,
.web .route-checkout .ion-padding .absolute-content .flex-min*/ {
	padding: 0 32px;
}
/* .order-details {
	background: transparent url(../../assets/images/order-completed-bgd.jpg) no-repeat 100% 125%;
	background-size: 100% auto;
	z-index: 1;
} */



.order-details::after {
	position: absolute;
	left: 0; top: 0; right: 0; bottom: 0;
	display: block;
	content: '';
	background-image: linear-gradient(var(--okx-background-color) 82%, rgba(255,255,255,0) 115%);
}

.order-details ion-grid {
	-webkit-padding-start: 0;
	        padding-inline-start: 0;
	-webkit-padding-end: 0;
	        padding-inline-end: 0;
}

.order-details ion-col {
	font-weight: normal;
}

.order-details .okx-box-footer {
	border-top: 2px solid var(--okx-border-color);
	padding-left: 0;
	margin-left: 0;
	padding-right: 0;
	margin-right: 0;
}

.order-details .okx-box-footer ion-row {
	border-bottom: 0 none;
}

.order-details .okx-box-header-grid ion-row:nth-of-type(1n) {
	border: 0;
}

.amount-paid{
	padding: 10px 20px;
}

.web ion-content.route-order-completed {
	--background: transparent none;
	--ion-color-white: transparent;
}

.web ion-content.route-order-completed {
	background-color: #fff;
	background-image: url(/static/media/dashboard-03.45aec6a4.jpg);
	background-repeat: no-repeat;
	background-position: 100% 0;
	background-size: cover;
}

.web #main > ion-content.route-order-completed > .ion-padding {
	position: absolute;
	left: 50%;
	top: 50%;
	/* bottom: 50px; */
	width: var(--okx-bsmodal-width-lg);
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	margin-left: 0;
	background-color: #fff;
	border-radius: var(--okx-box-radius);
	box-shadow: 0 0 32px rgba(0,0,0,0.25);
	padding: 64px;
	min-height: 75%;
}

.web #main > ion-content.route-order-completed > .ion-padding > .absolute-content.order-details {
	background: none;
	overflow-y: auto;
}

.web #main > ion-content.route-order-completed > .ion-padding > .absolute-content.order-details:after {
	border-radius: var(--okx-box-radius);
	box-shadow: 0 0 32px rgba(0,0,0,0.25);
}

.web #main > ion-content.route-order-completed > .ion-padding > .absolute-content.order-details > div.scrollable-y {
	height: 100%;
}

.web .order-details .okx-box,
.web .order-details .okx-box-header {
	border-color: var(--okx-border-color);
	border-width: 1px;
}


.web .order-details .okx-box-header-grid ion-row:not(:last-child) ion-col {
	padding: 7px 5px 0 5px;
}

.web .order-details .okx-box-header-grid ion-row:last-of-type ion-col {
	padding-bottom: 7px;
}

.web .order-details .okx-box-header-grid ion-row ion-col:nth-of-type(odd) strong{
	font-weight: normal;
}

.web .order-details .okx-box-header-grid ion-row ion-col:nth-of-type(even) {
	text-align: right;
	font-weight: bold;
}

@media screen and (min-width: 1441px) {
    .web #main > ion-content.route-history-details > .ion-padding {
        min-height: 65%;
    }
}
.web ion-content.route-contact-details {
	--background: transparent;
	--ion-color-white: transparent;
}
.table-number-input {
	--padding-start: 0;
	border-bottom: 1px solid;
	border-bottom-color: var(--okx-border-color);
	margin-left: 30px;
}

#main>ion-content.route-order-to-table .select-picker-label,
#main>ion-content.route-order-to-table .time-picker-label {
	color: var(--ion-color-primary);
	font-size: 12px;
	font-family: primary-bold;
}

.web ion-content.route-order-to-table {
	background-size: 100% auto;
	background-position: 100% 0;
	background-repeat: no-repeat;
}

.click-collect-wrapper {
	margin: var(--ion-padding) 0;
	height: 100%;
}

.click-collect-selectors {
	margin: 50px 0 40px 0;
}

.click-collect-button {
	position: absolute;
	bottom: 20px;
	width: calc(100vw - 40px);
	left: 20px;
}

.click-collect-layout {
	height: 100%;
}

.no-padding {
	height: 100%;
}

.click-collect-dialog-header {
	padding: 0 var(--ion-padding);
}

.web .click-collect-wrapper {
	margin: var(--ion-padding) 0;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
}

.web .click-collect-button {
	position: -webkit-sticky;
	position: sticky;
	width: unset;
	margin: unset;
	left: 0;
	padding: 0 var(--ion-padding);
}

.web .no-padding {
	height: 80%;
}

.route-order-to-table .click-collect-layout {
	height: unset !important;
}

.table-number {
	display: -webkit-flex;
	display: flex;
	padding: 0 var(--ion-padding) 10px var(--ion-padding);
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: flex-start;
	        align-items: flex-start
}

/* .table-number .mbsc-fr{
	width: 100%;
  } */
.route-order-to-table .click-collect-dialog-content {
	padding: 0;
}

.shadowed-box-light {
	border-bottom: 1px solid var(--ion-color-light);
}

.table-number .mbsc-select-input.mbsc-control {
	display: none;
}

.web .route-order-to-table .click-collect-layout {
	overflow: auto !important;
	padding-bottom: var(--ion-padding) !important;
}

.order-to-table-picker-wrapper {
	border: 1px solid var(--ion-color-light);
	padding: 0 2px;
	border-radius: var(--okx-box-radius);
	overflow: hidden;
	margin: auto;
}

.web ion-content.route-order-to-table {
	--background: url(/static/media/Click&Collect.f1d29dc9.jpg) no-repeat 50% 100% / 100%;
	--ion-color-white: transparent;
}
.toggle {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  
  .web #main > ion-content.route-register {
    z-index: 5;
  }
  
.route-order .segment-holder {
  padding: 0;
}

.order-categories {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
}

.order-categories h2 {
  padding: 0 25px;
}

.order-categories-inner {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row;
          flex-flow: row;
  width: 100%;
  overflow-y: auto;
}

.order-content {
  -webkit-flex: 1 1 100%;
          flex: 1 1 100%;
  overflow: hidden;
  padding: 0 var(--ion-padding);
}

.category-button {
  --color: var(--ion-color-black);
  font-weight: bold;
  --border-radius: 14px;
  margin: 10px 0;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--ion-color-light);
  text-transform: capitalize !important;
  height: 30px !important;
}
.order-categories-inner::-webkit-scrollbar {
  visibility: hidden;
}
.category-button.active {
  border-bottom: 2px solid var(--ion-color-secondary);
  /* --border-width: 1px;
  --border-style: solid;
  --border-color: var(--ion-color-white);
  --background-activated: var(--ion-color-primary); */
}

.order-list-items {
  height: 100%;
  overflow: auto;
}
.web .order-list-items {
  overflow: unset;
}
.order-list-items > .list-md {
  padding-bottom: 0;
  position: relative;
}

.order-list-items ion-item {
  --min-height: 20px;
  --inner-padding-end: 0;
  cursor: pointer;
}

.order-list-item {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  margin: 8px 0;
  padding: 10px 0;
  border-bottom: 1px solid var(--ion-color-light);
}

.order-list-item > div {
  -webkit-flex: 0 1 auto;
          flex: 0 1 auto;
  margin-left: 16px;
}
.order-list-item > div:first-child {
  margin-left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
/* .order-list-item > div:nth-child(2) {
  flex: 1 1 auto;
} */
.order-list-item > div:nth-child(3) {
  text-align: right;
  margin-right: 10px;
}
.item-content div {
  color: var(--ion-color-gray);
}
.item-content > h5 {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
  color: var(--ion-color-dark);
}
.item-content p {
  margin: 3px 0 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.item-image {
  background: none transparent no-repeat 50% 50%;
  background-size: 100% auto;
  width: 50px;
  padding-top: 100%;
}
.item-price > span:first-child {
  color: var(--ion-color-dark);
  font-size: 13px;
  font-weight: bold;
}
.item-price > span.original-price {
  text-decoration: line-through;
  font-size: 0.9em;
}
.order-list-header {
  padding: 25px;
  margin-bottom: 10px;
}

.order-list-header ion-label {
  font-size: 25px;
  font-weight: 600;
  text-transform: none;
  text-transform: initial;
}

.order-sublist-header {
  padding-left: 0;
  position: sticky;
  position: -webkit-sticky;
  top: -1px;
  z-index: 2;
  --color: var(--ion-color-dark);
  background-color: var(--okx-background-color);
}

.order-sublist-header.empty {
  /* max-height: 0;
	height: 0px;
	overflow: hidden;
	min-height: 0; */
  display: none;
}

.order-sublist-header ion-label {
  font-size: 20px;
  font-weight: bold;
  text-transform: none;
  text-transform: initial;
  font-family: var(--ion-font-family-bold);
  color: var(--okx-color-black);
  margin-top: 14px;
}

.order-intro-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-align-items: center;
          align-items: center;
  background: var(--ion-color-light-shade);
}

.order-intro-image-wrapper {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: var(--ion-color-black);
  background: var(--ion-color-light-shade);
  width: 100%;
}

.order-intro-content {
  height: 250px;
  padding: 25px;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  background: var(--ion-color-medium-contrast);
}

.order-intro-content p {
  color: var(--ion-color-black);
  text-align: center;
  padding: 0 10px;
  font-size: 14px;
  margin: 25px 0;
}

.order-intro-modal > .modal-wrapper {
  height: 320px;
  width: 270px;
  border-radius: 15px;
}

.order-intro-modal > .modal-wrapper > .ion-page {
  min-width: 100%;
  padding: 15px 25px;
}

.order-intro-modal > .modal-wrapper > .ion-page ion-icon {
  position: absolute;
  right: 15px;
  font-size: 35px;
}

.order-intro-modal > .modal-wrapper > .ion-page h2 {
  font-size: 20px;
  margin-top: 4px;
}

.order-intro-modal > .modal-wrapper > .ion-page .order-intro-modal-inner {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.location-select {
  --padding-start: 0;
  margin-top: 5px;
  border-bottom: 1px solid var(--ion-color-medium);
}

.popover-content ion-item ion-label {
  margin-left: 10px;
}

.pickup-time {
  margin-top: 5px;
  border-bottom: 1px solid var(--ion-color-medium);
}

.item-content {
  margin-right: auto;
}

.item-image {
  margin-left: auto;
  min-width: 50px;
  min-height: 50px;
  width: 50px;
  height: 50px;
}
.app {
  display: grid;
  grid-template-columns: 20% 80%;
}
.navbar {
  padding: 0.5rem;
}
.navbar .nav-link {
  padding: 0.5rem;
}
.header {
  height: 80px;
  overflow: auto;
  background: #aaa;
}
.container {
  height: 500px;
  background: #ddd;
}
.gap {
  height: 500px;
}
.gap.short {
  height: 250px;
}
.gap.tall {
  height: 1000px;
}
.container.relative {
  overflow-y: auto;
}

.view-order-button {
  position: fixed;
  bottom: 10px;
  text-align: center;
  z-index: 3;
  left: 14px;
  right: 14px;
}
.view-order-button ion-button{
  --border-radius: 0 !important;
}
.segment-holder.grid-menus {
  overflow: auto !important;
}
.segment-holder.grid-menus .order-categories {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
}
.order-categories-tiles-wrapper {
  width: 100%;
  margin-bottom: 20px;
}
.order-categories-two-columns-tiles-wrapper,.order-list-items ion-list > ion-item {
  width: calc(50% - 10px);
  margin-bottom: 20px;
}
.order-categories-two-columns-tiles-wrapper:nth-child(odd),.order-list-items ion-list > ion-item:nth-child(odd){
margin-right: 10px;
}
.order-categories-two-columns-tiles-wrapper:nth-child(even),.order-list-items ion-list > ion-item:nth-child(even){
  margin-left: 10px;
}
.order-categories-tile {
  height: 150px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  background-blend-mode: darken;
  text-align: center;
  font-weight: bold;
  text-transform: capitalize;
  /* padding: 20px; */
  
}
.grid-menus .order-categories {
  padding: 0;
}
.route-order > .no-padding > .segment-holder.grid-menus > .order-categories > ion-text > span {
  padding-left: 30px;
  padding-bottom: 10px;
}
.grid-menus .order-categories-tile-title {
  color: var(--ion-color-primary-contrast);
  font-size: 22px;
  text-transform: capitalize;
}
.grid-menus .order-list-item {
  display: grid;
  margin: 0;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    'image image'
    'desc desc'
    'price icon';
  -webkit-align-items: flex-end;
          align-items: flex-end;
  padding: 0;
  border: none;
}
.grid-menus .order-list-items ion-item {
  --border-style: unset;
  background-color: var(--ion-color-white);
  width: calc(50% - 10px);
  margin-bottom: 20px;
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
}
/* .grid-menus .order-list-items ion-item:nth-of-type(odd){
  margin-right: 10px;
}
.grid-menus .order-list-items ion-item:nth-of-type(even){
  margin-left: 10px;
} */
.grid-menus .item-content {
  margin: 0;
  grid-area: desc;
}
.grid-menus .item-content > div {
  display: none;
}
.grid-menus .item-price {
  grid-area: price;
  margin: 0;
}
.grid-menus .item-icon-wrapper {
  grid-area: icon;
}
.grid-menus .item-icon-wrapper ion-icon {
  height: 20px;
  width: 20px;
  opacity: 0.4;
}
.grid-menus .item-price > span:first-child {
  font-size: 18px;
  font-weight: 400;
}
.grid-menus .default-height {
  display: none;
}
.grid-menus .item-content > h5 {
  font-size: 18px;
  font-weight: bolder;
  font-weight: 900;
  letter-spacing: normal;
  min-height: 50px;
  text-align: start;
}
.grid-menus .order-list-item > div:first-child {
  -webkit-flex: unset;
          flex: unset;
}
.grid-menus .item-image-wrapper {
  width: 100%;
  margin: 0;
  grid-area: image;
}
.grid-menus .item-image {
  width: 100%;
  height: 150px;
  padding: 0;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.2);
  background-size: cover;
}

.grid-menus .category-title {
  margin: 10px 10px 10px 20px;
  font-size: 20px;
  font-weight: bold;
}
.grid-menus .order-sublist-holder {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.grid-menus .order-list-items ion-list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 0 var(--ion-padding);
  margin-bottom: 50px;
}
.no-items-message{
  margin: var(--ion-padding);
  display: block;
}
@media (max-width: 700px) {
  .app {
    grid-template-columns: unset;
  }
}



.search-box-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin: 0 20px 30px 0;
}
.search-box {
  position: relative;
  padding: 0;
  position: -webkit-sticky;
  position: sticky;
  z-index: 5;
  top: -1px;
  background-color: var(--ion-color-white);
  border: 1px solid var(--ion-color-light);
  margin: 5px var(--ion-padding);
}
.search-box ion-icon {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 1px;
  left: 10px;
  fill: var(--ion-color-secondary);
  z-index: 6;
}
.search-box ion-icon.reset-icon{
  left: unset;
  right: 10px;
  cursor: pointer;
}
.search-box ion-input {
  --padding-start: 30px;
  --padding-end:30px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  width: 100%;
  background-color: var(--ion-color-white);

  border-radius: 0;
}
.grid-order-list-item{
  padding-bottom: 5px!important;
}
.grid-order-list-item .item-price, .grid-order-list-item .item-content{
  padding: 5px 5px 0 !important;
}
.grid-order-list-item .item-content .strong-text{
  display: block;
  line-height: 1.2;
}
.order-categories {
	-webkit-flex: 0 0 auto;
	        flex: 0 0 auto;
	padding: 0;
  }
  .web #main > ion-content.route-order > .no-padding {
	height: 100%;
	overflow-y: auto;
	/* margin-top: 50px; */
	background-color: var(--okx-background-color);
  }
  
  .order-categories h2 {
	padding: 0 25px;
  }
  
  .order-categories-inner {
	display: -webkit-flex;
	display: flex;
	width: 100%;
	overflow-y: auto;
  }
  .order-categories-inner > ion-button {
	height: 25px;
  }
  .order-content {
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
	padding: 0 25px;
	overflow: hidden;
  }
  
  .category-button {
	--color: var(--ion-color-primary);
	text-transform: uppercase;
	height: 25px;
	padding-bottom: 5px;
	border-bottom: 2px solid var(--ion-color-light);
  }
  
  .web div.order-categories-inner > ion-button:first-child {
	margin-left: 0px;
  }
  .category-button {
	border-bottom: 0;
  }
  .category-button.active {
	border-bottom: 2px solid var(--ion-color-secondary);
	font-weight: bold;
  }
  .web .order-list-items {
	overflow-y: auto;
	height: 100%;
	padding-right: 30px;
  }
  
  .item-content {
	font-size: 0.8em;
  }
  .item-content > h5 {
	margin: 0;
	font-size: 13px;
	font-weight: bold;
	color: var(--ion-color-dark);
  }
  .item-content p {
	margin: 0;
  }
  .order-list-header {
	padding: 25px;
	margin-bottom: 10px;
  }
  
  .order-list-header ion-label {
	font-size: 25px;
	font-weight: 600;
	text-transform: none;
	text-transform: initial;
  }
  
  .order-sublist-header {
	padding-left: 0;
	position: sticky;
	position: -webkit-sticky;
	top: -1px;
	background-color: var(--okx-background-color);
	z-index: 999;
	--color: var(--ion-color-dark);
  }
  .order-sublist-header ion-label {
	font-size: 20px;
	text-transform: none;
	text-transform: initial;
  }
  
  .order-sublist-header.empty {
	display: none;
  }
  
  .order-intro-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-flow: column;
	        flex-flow: column;
	-webkit-align-items: center;
	        align-items: center;
	background: var(--ion-color-light-shade);
  }
  
  .order-intro-image-wrapper {
	-webkit-flex: 1 1;
	        flex: 1 1;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	color: var(--ion-color-black);
	background: var(--ion-color-light-shade);
	width: 100%;
  }
  
  .order-intro-content {
	height: 250px;
	padding: 25px;
	border-top-left-radius: 35px;
	border-top-right-radius: 35px;
	background: var(--ion-color-medium-contrast);
  }
  
  .order-intro-content p {
	color: var(--ion-color-black);
	text-align: center;
	padding: 0 10px;
	font-size: var(--ion-font-size);
	margin: 25px 0;
  }
  
  .order-intro-modal > .modal-wrapper {
	height: 320px;
	width: 270px;
	border-radius: 15px;
  }
  
  .order-intro-modal > .modal-wrapper > .ion-page {
	min-width: 100%;
	padding: 15px 25px;
  }
  
  .order-intro-modal > .modal-wrapper > .ion-page ion-icon {
	position: absolute;
	right: 15px;
	font-size: 35px;
  }
  
  .order-intro-modal > .modal-wrapper > .ion-page h2 {
	font-size: 20px;
	margin-top: 4px;
  }
  
  .order-intro-modal > .modal-wrapper > .ion-page .order-intro-modal-inner {
	-webkit-flex: 1 1;
	        flex: 1 1;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-flow: column;
	        flex-flow: column;
	-webkit-justify-content: space-around;
	        justify-content: space-around;
  }
  
  .location-select {
	--padding-start: 0;
	margin-top: 5px;
	border-bottom: 1px solid var(--ion-color-medium);
  }
  
  .popover-content ion-item ion-label {
	margin-left: 10px;
  }
  
  .pickup-time {
	margin-top: 5px;
	border-bottom: 1px solid var(--ion-color-medium);
  }
  
  .item-content {
	margin-right: auto;
  }
  
  .app {
	display: grid;
	grid-template-columns: 20% 80%;
  }
  .navbar {
	padding: 0.5rem;
  }
  .navbar .nav-link {
	padding: 0.5rem;
  }
  .header {
	height: 80px;
	overflow: auto;
	background: #aaa;
  }
  .container {
	height: 500px;
	background: #ddd;
  }
  .gap {
	height: 500px;
  }
  .gap.short {
	height: 250px;
  }
  .gap.tall {
	height: 1000px;
  }
  .container.relative {
	overflow-y: auto;
  }
  
  .view-order-button {
	position: fixed;
	bottom: 0;
	font-family: var(--ion-font-family);
	text-align: center;
	z-index: 1;
	width: 100%;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
  }
  
  @media (max-width: 700px) {
	.app {
	  grid-template-columns: unset;
	}
  }
  
  @supports (-webkit-overflow-scrolling: touch) {
	/* iOS */
	.order-list-items {
	  height: 100vh;
	}
  }
  
  /* new */
  
  .web .order-list {
  width: calc(100% - var(--okx-sidebar-width));
  }
  
  .web .order-list.order-list-full {
	width: 100%;
  }
  
  .web .order-summary-sidebar,
  .web .order-summary-sidebar::before,
  .web .order-summary-sidebar::after {
	left: calc(100% - var(--okx-sidebar-width));
  }
  
  .web .order-summary-sidebar {
	position: absolute;
	top: 0;
	right: 10px;
	bottom: 0;
  }
  .web .no-items-message{
	margin: var(--ion-padding) var(--ion-padding) var(--ion-padding) 0;
	display: block;
  }
  .web .order-summary-sidebar .add-items-row {
	display: none;
  }
  .order-summary-sidebar .add-items-btn {
display: none;
  }
  .web .order-summary-sidebar::before,
  .web .order-summary-sidebar::after {
	content: '';
	position: fixed;
	top: -200px;
	bottom: 0;
  }
  
  .web .order-summary-sidebar::before {
	width: 20px;
	box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
  }
  
  .web .order-summary-sidebar::after {
	width: 50px;
	background-color: var(--okx-background-color);
  }
  
  .web .order-summary-sidebar .absolute-content {
	top: 0;
	z-index: 5;
	padding: 0  !important;
	background-color: var(--okx-background-color);
  }
  .web .order-summary-sidebar .absolute-content .scrollable-y {
	padding: var(--ion-padding) var(--ion-padding);
  }
  /* .web .order-summary-sidebar .title {
	padding-left: 0;
	font-size: var(--okx-subtitle-font-size);
  } */
  
  .web .order-summary-sidebar .checkout div.righted:first-of-type {
	visibility: hidden;
  }
  
  .web .static.route-order {
	background-color: var(--okx-background-color);
  }
  
  .web .order-categories {
	padding: 20px 0 0 50px;
	margin-right: 50px;
	position: sticky;
	position: -webkit-sticky;
	top: 0;
	z-index: 3;
	background-color: var(--okx-background-color);
  }
  
  .web .order-categories h2 {
	padding: 0;
	font-size: var(--okx-title-font-size);
  }
  
  .web .order-categories-inner {
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	padding-left: 0;
  }
  
  /* .web .order-categories-inner ion-button {
	  text-transform: none;
  } */
  
  .web .order-content {
	padding: 0 10px 20px 50px;
	overflow: unset;
  }
  .web .order-sublist-header {
	background-color: var(--okx-background-color);
  }
  .web .order-sublist-header ion-label {
	color: var(--ion-color-primary);
	font-weight: bold;
  }
  /* grid */
  
  .web .order-list-grid {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	justify-items: stretch;
	-webkit-align-items: stretch;
	        align-items: stretch;
	grid-row-gap: 15px;
	row-gap: 15px;
	grid-column-gap: 15px;
	-webkit-column-gap: 15px;
	        column-gap: 15px;
	padding-right: 10px;
  }
  
  .web .order-list-grid .order-list-item {
	display: block;
	width: auto;
	margin-bottom: 0;
	min-width: 0;
	background-color: #fff;
	padding: 8px;
	padding-bottom: 2px;
	/*border: 1px solid #eee;*/
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
	cursor: pointer;
  }
  
  .web .order-list-grid .order-list-item .item-image {
	position: relative;
	width: 100%;
	padding-top: 100%;
	background-color: #ddd;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	min-width: 0;
	min-height: 0;
	margin: 0;
	display: block;
	height: auto;
	border-radius: 0;
  }
  
  .web .order-list-grid .order-list-item .item-image .item-image-bg {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
  }
  
  .web .order-list-grid .order-list-item .item-bar {
	margin: 5px 0 0 0;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	height: 30px;
	-webkit-align-items: center;
	        align-items: center;
  }
  
  .web .item-bar .item-name .small-text,
  .web .item-bar .item-price {
	-webkit-line-clamp: 2;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	color: var(--ion-color-primary) !important;
  }
  
  .web .route-order .item-bar .item-price {
	text-align: right;
	-webkit-align-self: center;
	        align-self: center;
	-webkit-flex: 0 1 50%;
	        flex: 0 1 50%;
  }
  
  .web .route-order .item-bar .item-name.ellipsis {
	font-weight: bold;
	white-space: normal;
	text-overflow: unset;
  }
  
  .web .item-bar .item-price {
	text-align: right;
  }
  
  .web .item-bar .item-price > span:first-child {
	color: var(--ion-color-primary);
	font-size: inherit;
	font-weight: bold;
  }
  
  .web .item-bar .item-price > span.original-price {
	text-decoration: line-through;
	font-size: calc(var(--okx-small-text-font-size) - 1px);
  }
  
  .web ion-modal.modal-classic {
	--width: 500px;
	--height: 80%;
	min-height: 400px;
  }
  
  .web ion-modal.modal-classic .modal-classic-wrapper,
  .web ion-modal.modal-classic .modal-classic-content {
	height: 100%;
  }
  
  .web ion-modal.modal-classic .modal-classic-content {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
  }
  
  /* .web ion-modal.modal-classic .item-details-card {
	overflow-y: hidden;
	display: grid;
	grid-template-columns: 200px auto;
	grid-template-rows: min-content auto min-content;
	border-radius: 0;
	height: 100%;
	gap: 10px;
  } */
  
  /* .web ion-modal.modal-classic .item-details-card .item-details-card-header {
	position: sticky;
	position: -webkit-sticky;
	top: 0;
	flex-flow: column;
	justify-content: center;
	align-items: flex-start;
	padding: 0 20px;
	transform: translate3d(0, 0, 0);
	-webkit-transform: translate3d(0, 0, 0);
	z-index: 1;
  } */
  .web ion-modal.modal-classic .item-details-card .item-details-card-header *,
  .web ion-modal.modal-classic .item-details-card .item-details-card-header .item-details-card-price {
	margin-bottom: 0 !important;
	-webkit-flex: unset;
	        flex: unset;
  }
  .web ion-modal.modal-classic .item-details-card .item-details-image {
	position: relative;
	height: 200px;
	transform: translate3d(0, 0, 0);
	-webkit-transform: translate3d(0, 0, 0);
	z-index: 1;
	grid-column: span 1;
	grid-row: span 1;
  }
  .web .item-details-card-content {
	grid-column: span 2;
	margin: 0;
  }
  .web ion-modal.modal-classic .item-details-card .item-details-image .item-details-image-bg {
	background-size: cover;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
  }
  
  
  .web ion-modal.modal-classic.allergen-modal,
  .web ion-modal.modal-classic.apply-points-modal {
	--width: var(--okx-sidebar-width);
	--height: 100%;
	--border-radius: 0;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
  }
  
  .web ion-modal.modal-classic.allergen-modal .modal-classic-wrapper,
  .web ion-modal.modal-classic.apply-points-modal .modal-classic-wrapper {
	left: calc(100% - var(--okx-sidebar-width));
  }
  
  .web ion-modal.modal-classic.allergen-modal .absolute-content,
  .web ion-modal.modal-classic.apply-points-modal .absolute-content {
	padding-top: 54px;
  }
  
  /* .web ion-modal.modal-classic.allergen-modal .rhino {
	  background: transparent url(../../assets/images/rhino.svg) no-repeat 50% 100% / 180px auto;
  } */
  .web ion-modal.modal-classic.apply-points-modal .incrementer-decrease,
  .web ion-modal.modal-classic.apply-points-modal .incrementer-incease {
	--color: var(--ion-color-secondary);
  }
  
  .web .order-sublist-holder {
	margin-bottom: 30px;
	grid-column: 1 / -1;
  }
  
  .web .order-sublist-holder:last-of-type {
	margin-bottom: 0px;
  }
  
  .web ion-modal.modal-classic.apply-points-modal .incrementer {
	display: grid;
	grid-template-columns: 38px auto 38px;
	grid-column-gap: 5px;
	padding: 0;
  }
  
  .web ion-modal.modal-classic.allergen-modal .title.centered {
	text-align: left;
  }
  
  .web ion-modal.modal-classic.allergen-modal .sectiontitle {
	margin: 0;
	font-weight: bold;
  }
  
  .web ion-modal.modal-classic .item-details-card-price {
	text-align: left;
  }
  
  .web .item-details-modal .item-details-actions {
	left: 0 !important;
	right: 0 !important;
	bottom: 0 !important;
	width: 100%;
  }
  
  .web ion-modal.modal-classic .item-details-image {
	position: absolute;
	left: -12px;
	width: 130px;
	padding-top: 240px;
	height: auto;
	background-size: 100% auto;
	transform: translate3d(0, 0, 0);
	-webkit-transform: translate3d(0, 0, 0);
	z-index: 3;
  }
  
  .web ion-modal.modal-classic .item-details-content {
	height: calc(100% - 90px);
	overflow-y: auto;
	padding: 0;
  }
  .web .item-details-modal-info > div,
  .web .item-details-card-content {
	padding: 0 20px;
  }
  
  .web ion-modal.modal-classic .item-details-card {
	height: 100%;
	position: relative;
  }
  
  .web ion-modal.modal-classic .item-details-card-price {
	text-align: left;
  }
  
  .web ion-modal.modal-classic .item-details-card-content .item-details-card-list {
	padding: 0;
  }
  
  .web .item-details-modal .item-details-actions {
	left: 0 !important;
	right: 0 !important;
	bottom: 0 !important;
  }
  .item-details-modal .modal-classic-wrapper,
  .item-details-modal .modal-classic-content {
	padding: 0 !important;
  }
  .item-details-model-header {
	padding: 20px;
	left: 0;
	background: var(--okx-background-color);
	z-index: 1;
	top: 0;
	position: absolute;
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
	width: 100%;
	-webkit-transform: translateY(-100%);
	        transform: translateY(-100%);
	transition: -webkit-transform 0.3s ease-in-out;
	transition: transform 0.3s ease-in-out;
	transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  }
  .item-details-model-header.active {
	-webkit-transform: translateY(0);
	        transform: translateY(0);
	z-index: 2;
  }
  
  .item-details-modal-info img {
	height: 200px;
	width: 100%;
	object-fit: cover;
	margin-bottom: 20px;
  }
  ion-modal.modal-classic.item-details-modal .modal-classic-closer {
	background: var(--okx-background-color);
	right: 15px;
	top: 12px !important;
	border-radius: 2px;
	height: 35px;
	width: 35px;
	z-index: 5;
	box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 20%);
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	padding: 5px;
  }
  ion-modal.modal-classic.item-details-modal .modal-classic-closer ion-icon{
	height: 25px;
	width: 25px;
  }
  .no-searched-products-message {
	text-transform: uppercase;
	margin-left: 0;
	font-size: var(--okx-title-font-size);
  }
  .search-box-wrapper {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
  }
  .web .search-box-wrapper{
	margin: 0 0 30px 0;
  
  }
  .search-box {
	position: relative;
	background-color: var(--ion-color-white);
	border: 1px solid var(--ion-color-light);
  
  }
  .web .search-box{
	margin: 0;
  }
  .web .search-box ion-icon{
	left: 5px;
  }
  .search-box ion-icon {
	position: absolute;
	margin: auto;
	top: 0;
	bottom: 1px;
	left: 10px;
	fill: var(--ion-color-secondary);
  }
  .web .search-box ion-icon.reset-icon{
	left: unset;
	right: 10px;
	cursor: pointer;
  
  }
  .web .search-box ion-input {
	--padding-start: 30px;
	--padding-end:30px;
	--padding-top: 5px;
	--padding-bottom: 5px;
	width: 250px;
  
	border-radius: 0;
  }
  @media screen and (max-width: 1280px) {
	.web .order-list-grid {
	  grid-template-columns: 1fr 1fr 1fr 1fr;
	}
  }
  
  @media screen and (max-width: 860px) {
	.web .order-list-grid {
	  grid-template-columns: 1fr 1fr 1fr;
	}
  }
  
  @media screen and (max-width: 640px) {
	.web .order-list-grid {
	  grid-template-columns: 1fr 1fr;
	}
  }
  
  
  .web .web-only.order-list-heading {
	position: relative;
	margin-bottom: 50px;
  }
  .restaurant-info-banner {
	width: 350px;
	background: var(--ion-color-white);
	height: 250px;
	border-radius: var(--okx-box-radius);
	position: absolute;
	top: 50px;
	right: 50px;
	z-index: 5;
	padding: 15px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  }
  .table-icon {
	background-image: url(/static/media/table-icon.c6270b49.svg);
  }
  .delivery-icon {
	background-image: url(/static/media/delivery-icon.e354a3b8.svg);
  }
  .collection-icon {
	background-image: url(/static/media/collection-icon.f29ca522.svg);
  }
  .restaurant-info-delivery-options {
	display: -webkit-flex;
	display: flex;
	width: 100%;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
  }
  .restaurant-info-delivery-options .icons-wrapper {
	display: -webkit-flex;
	display: flex;
  }
  .restaurant-info-delivery-options .icons-wrapper svg {
	stroke: var(--ion-color-dark);
  }
  .restaurant-info-delivery-options .icons-wrapper > div {
	height: 40px;
	margin: 0 5px;
	width: 40px;
	object-fit: contain;
	background-repeat: no-repeat;
	cursor: pointer;
  }
  .restaurant-info-delivery-options .icons-wrapper > div:last-child {
	margin-right: 0;
  }
  .web .web-only.order-list-heading > .order-list-restaurant-info,
  .web .web-only.order-list-heading > .order-list-restaurant-image {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-flow: column;
	        flex-flow: column;
	position: relative;
  }
  .web .web-only.order-list-heading > .order-list-restaurant-info {
	padding: 32px 50px;
  }
  .web .order-list-info-box,
  .web .order-list-info-box .info-box-list {
	display: -webkit-flex;
	display: flex;
  }
  .client-logo {
	height: 50px;
	width: 100px;
	object-fit: contain;
	margin-bottom: 20px;
  }
  .web .order-list-info-box {
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
  }
  
  .web .order-list-info-box > ion-icon {
	font-size: 30px;
  }
  
  .web .order-list-info-box .info-box-list {
	-webkit-flex-flow: column;
	        flex-flow: column;
  }
  
  .web .order-list-info-box .info-box-list > ion-list {
	padding: 0;
	display: -webkit-flex;
	display: flex;
  }
  
  .web .order-list-info-box .info-box-list > ion-list > ion-button {
	height: auto;
	height: initial;
	margin: 0 5px 0 0;
  }
  
  .web .order-list-info-box .info-box-list > ion-list > ion-button:hover {
	background: var(--ion-color-white);
  }
  
  .web .order-list-restaurant-bg {
	background-size: cover;
	background-repeat: no-repeat;
	-webkit-flex: 0 1 250px;
	        flex: 0 1 250px;
	position: relative;
	background-image: url(/static/media/delivery-bgd.f5c0ff1e.jpg);
	background-position: center;
  }
  .web .order-list-restaurant-bg:before {
	content: '';
	background: linear-gradient(to right, rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 1) 100%);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
  }

  .web .image-placeholder{
	height: 200px;
	width: 100%;
	object-fit: cover;
	margin-bottom: 20px;
  }
.okx-validate-input-wrapper {
	display: -webkit-flex;
	display: flex;
	width: 100%;
}

.okx-validate-input-wrapper .okx-validate-input-holder {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    /* padding-bottom: 5px; */
}

.okx-validate-input-wrapper .okx-validate-input-holder ion-datetime {
    --padding-start: 0;
    --padding-top: 15px;
}
.okx-validate-input-wrapper .okx-validate-input-holder p {
    font-family: var(--ion-default-font-light);
    font-size: 12px;
    font-weight: bold;
    /* margin: 21px 0 0 0; */
    display: -webkit-flex;
    display: flex;
    /* padding-left: 2px; */
    color: inherit;
}

.okx-validate-input-wrapper .okx-validate-input-holder ion-label,
.okx-validate-input-holder > .date-picker-wrapper .date-picker-modal-label {
    font-family: var(--ion-default-font-light);
    font-size: 13px;
    font-weight: bold;
}

.okx-validate-input-holder > .date-picker-wrapper .date-picker-modal-label {
    font-size: 11px;
}

.okx-validate-input-wrapper ion-button {
    -webkit-flex: 1 0 auto;
            flex: 1 0 auto;
    min-width: 32px;
    /* padding-top: 10px; */
}

.date-picker-wrapper.modal .data-picker-input {
    padding-top: 15px;
}

.date-picker-wrapper.modal ion-label {
    font-size: 8px;
}

.date-picker-modal-label {
	font-size: 9px;
    color: var(--ion-color-dark);
    font-weight: normal;
	/* margin-left: 2px; */
	transition: .1s ease-in;
}

.validate-modal > .modal-wrapper {
	max-height: 72vh;
	width: 80vw;
	border-radius: 15px;
	text-align: center;
}

.validate-modal > .modal-wrapper > .ion-page {
min-width: 100%;
padding: 15px 25px;
overflow: auto;
background-color: var(--okx-background-color);
}

.validate-modal > .modal-wrapper > .ion-page h2 {
font-size: 20px;
margin-top: 4px;
margin-bottom: 4px;
}

.validate-modal > .modal-wrapper > .ion-page ion-icon {
position: absolute;
right: 0;
font-size: 15px;
}

.validate-modal ion-item {
--min-height: 20px;
--inner-padding-end: 0;
}

.modal-content {
margin-top: 20px;
}

.validate-modal > .modal-wrapper .ion-page .inline-input > ion-text, 
.validate-modal > .modal-wrapper .ion-page .inline-input > label {
-webkit-flex: 0 0 40%;
        flex: 0 0 40%;
}

.validate-modal > .modal-wrapper .ion-page .inline-input > ion-input[name="email"] {
text-overflow: ellipsis;
}

.web .validate-modal > .modal-wrapper .ion-page {
padding-top: 60px;
}

.web .validate-modal > .modal-wrapper {
max-width: var(--okx-bsmodal-width-md);
width: 100%;
}

.web .validate-modal > .modal-wrapper .validate-modal-closer {
display: inline-block;
position: absolute;
top: 15px;
right: 15px;
cursor: pointer;
}

.web .validate-modal > .modal-wrapper .validate-modal-closer ion-icon {
font-size: 20px;
}

.web .validate-modal > .modal-wrapper .validate-modal-header > br {
display: none;
}

.web .validate-modal > .modal-wrapper .validate-modal-header {
text-align: left;
}

.web .validate-modal > .modal-wrapper .validate-modal-header ion-text .title {
font-weight: bold;
}

.web .validate-modal > .modal-wrapper .validate-modal-header ion-text .small-text {
color: var(--ion-color-gray);
}
.menu-enabled .list-md {
  padding-top: 0;
  padding-bottom: 0;
}

.drawer-menu .title-default {
  text-transform: uppercase;
  font-weight: bold;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
}

.drawer-menu ion-header,
.drawer-menu ion-content,
.drawer-menu ion-footer {
  --padding-start: var(--okx-drawer-padding);
  --padding-end: var(--okx-drawer-padding);
  padding-left: var(--okx-drawer-padding);
  padding-right: var(--okx-drawer-padding);
}

.drawer-menu ion-header {
  padding-top: 10px;
  padding-bottom: 10px;
}

.drawer-menu ion-footer {
  padding-top: 5px;
  padding-bottom: 20px;
  position: relative;
}

.drawer-menu ion-footer::before {
  background-image: none;
}

.drawer-menu .nav-logo {
  width: 100%;
  height: 68px;
  background: url(/static/media/logo-main.193dd6f3.svg) transparent no-repeat 0% 50% / auto 33%;
}

.drawer-menu ion-content {
  --ion-color-base: transparent !important;
  --background: transparent;
}

.drawer-menu .nav-label {
  font-size: var(--ion-font-size);
  text-transform: capitalize;
  color: var(--ion-color-primary-contrast);
}

.drawer-menu .terms-items .nav-label {
  color: rgba(var(--ion-color-white-rgb), 0.6);
  font-weight: normal;
}

.drawer-menu .nav-item {
  --ion-color-base: transparent !important;
  --padding-start: 0;
  --min-height: 24px;
}

.drawer-menu .nav-item .item-detail-icon {
  --oppacity: 0
}

ion-list.terms-items.ios.list-ios.list-lines-none.list-ios-lines-none.hydrated>ion-menu-toggle>ion-item>ion-label,
ion-list.terms-items.md.list-md.list-lines-none.list-md-lines-none.hydrated>ion-menu-toggle>ion-item>ion-label {
  color: rgba(var(--ion-color-white-rgb), 0.6);
}



.drawer-menu .nav-item.small-text {
  font-weight: normal;
}

.drawer-menu ion-header ion-toolbar:last-child {
  --border-width: 0 0 0;
  --ion-color-base: transparent !important;
}

.drawer-menu ion-footer>ion-label {

  opacity: 0.6;
}

.drawer-menu ion-icon {

  stroke: #fff;
}


.logo-5l>div {
  width: 100px;
  padding-top: 45%;
  background: url(/static/media/5loyalty_logo.994ee3c0.svg) transparent no-repeat 50% 50%;
  background-size: 100% auto;
}


ion-split-pane {
  --side-max-width: var(--okx-drawer-width);
  --side-min-width: var(--okx-drawer-width);
  --side-width: var(--okx-drawer-width);
  --border: none;
}

ion-menu {
  --background: var(--ion-color-primary)
}

/* 
  
  ion-menu ion-content::after,
  ion-menu ion-content::before,
  .web ion-menu::before,
  .web ion-menu::after {
	position: absolute;
	content: '';
	height: 100px;
	width: 100px;
	background-image: url(../../assets/images/membership-flower.png);
	z-index: 2;
	background-size: cover;
	background-repeat: no-repeat;
  }
  ion-menu ion-content::after {
	top: -40px;
	right: -50px;
  }
  .web ion-menu::after{
	top: 45px;
	right: -50px;
  }
  ion-menu ion-content::before,.web ion-menu::before {
	bottom: 10px;
	left: -50px;
  } */

.web ion-menu ion-content::after,
.web ion-menu ion-content::before {
  display: none;
}

.web ion-menu {
  --max-width: var(--okx-drawer-width);
}

ion-footer>ion-label[hidden] {
  display: block !important;
}

.collapse-drawer-icon,
ion-content.log-status {
  display: none;
}

/* .web ion-footer > ion-label[hidden] {
	  display: none !important;
  } */

.web .drawer-menu ion-header ion-toolbar {
  position: relative;
}

.web .drawer-menu .nav-logo {
  width: 90%;
  cursor: pointer;
  background-size: auto 30%;
  background-position: 0% 50%;
  fill: #fff;
}

.web .drawer-menu .nav-icon {
  font-size: 20px;
}

.web .small-drawer-header {
  padding: 15px 0 0 0;
}

.web .small-drawer-toolbar {
  --background: transparent;
}

ion-toolbar {
  --border-color: transparent !important;
}

.web .small-drawer-header ion-buttons {
  position: absolute;
  top: 1px;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
}

.web .small-drawer-header ion-button {
  --padding-top: 12px;
  --padding-end: 12px;
  --padding-bottom: 12px;
  --padding-start: 12px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  width: 48px;
  height: 48px;
  background: var(--ion-color-white);
}

.web .small-drawer-header ion-button ion-icon {
  font-size: 1.8rem;
  color: var(--okx-color-black);
}

.web .collapse-drawer-icon {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translate(0%, -50%);
          transform: translate(0%, -50%);
  cursor: pointer;
  font-size: 25px;
  color: var(--ion-color-primary-contrast);
}

.web ion-content.log-status {
  display: block;
  max-height: 115px;
}

.web .log-status-wrap {
  border-radius: var(--okx-box-radius);
  border: 1px solid var(--ion-color-gray);
  overflow: hidden;

}

.web .log-status-wrap ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
}

.web .log-status-wrap ion-item:first-of-type {
  --background: var(--okx-background-color);
  --border-radius: var(--okx-box-radius);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  z-index: 2;
}

.web .log-status-wrap ion-item:last-of-type {
  border-bottom-left-radius: var(--okx-box-radius);
  border-bottom-right-radius: var(--okx-box-radius);
  --min-height: 35px;
}

.web .log-status-wrap ion-item.logout-box {
  border-top: 1px solid var(--ion-color-gray);
}

.web .log-status-content {
  width: 100%;
  background-color: var(--okx-background-color);
}

.web .log-status-content>ion-text .subtitle {
  margin: 0;
  font-size: var(--ion-font-size);
  font-weight: 500;
  color: var(--ion-color-primary);
}

.web .log-status-content>ion-text .small-text {
  color: var(--ion-color-primary);
  opacity: 0.6;
  display: block;
  margin-top: -2px;
}

.web .log-status-wrap ion-icon {
  color: var(--ion-color-primary);
}

.web .logout-box>ion-label,
.web .logout-box>ion-label>ion-text {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 0;
}

.web .logout-box>ion-label>ion-icon {
  font-size: 17px;
  margin-right: 5px;
}

.web .logout-box>ion-label>ion-text .normal-text {
  font-size: 15px;
  margin-left: 5px;
}

.web .logout-box {
  margin-top: -40px;
  transition: all 0.3s ease;
}

.web .logout-box.visible {
  margin-top: 0;
}

.web .drawer-menu ion-content:first-of-type {
  --padding-start: 0px;
}

.web .drawer-menu ion-content:first-of-type ion-item {
  --padding-start: var(--okx-drawer-padding);
}

.web .drawer-menu ion-content:first-of-type ion-item.okx-nav-selected>ion-icon {
  background: var(--ion-color-secondary);
  border-radius: 50%;

}

.drawer-menu-order-button {
  margin: 0;
  width: 100%;
  margin-bottom: 15px;
}

.web .drawer-menu-order-button {
  padding: 0 0 0 var(--okx-drawer-padding);
}

.web .drawer-menu .nav-item {
  cursor: pointer;
}

.web .drawer-menu .nav-icon,
.drawer-menu .nav-label {
  margin-top: 0;
  margin-bottom: 0;

}


.web .drawer-menu .nav-item.small-text>.nav-icon,
.drawer-menu .nav-item.small-text>.nav-label {
  margin-top: 0;
  margin-bottom: 0;
}

.web .drawer-menu .nav-item.small-text>.nav-icon {
  display: none;
}

.drawer-menu .nav-item.small-text {
  --min-height: 26px;
  margin-left: 10px;
}

.web .drawer-menu ion-content:first-of-type ion-item.small-text.okx-nav-selected>ion-icon {
  background-color: transparent;
}

@media screen and (min-width: 992px) {
  .web .collapse-drawer-icon {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
    cursor: pointer;
    font-size: 25px;
  }
}

@media screen and (min-width: 768px) {
  .web .collapse-drawer-icon {
    display: none;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
    cursor: pointer;
    font-size: 25px;
  }
}

@media screen and (min-width: 1024px) {
  .web .collapse-drawer-icon {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
    cursor: pointer;
    font-size: 25px;
  }
}

.header-md::after {
  background-image: none;
}

.fiveloyalty-logo-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding: 15px 0 10px 0;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  margin-left: 5px;
  color: var(--ion-color-primary-contrast);
}

.fiveloyalty-logo-wrapper .small-text {
  color: var(--ion-color-primary-contrast);
}

.web .fiveloyalty-logo-wrapper {
  padding: 15px var(--okx-drawer-padding) 10px;
}

.fiveloyalty-logo-wrapper img {
  height: 30px;
  margin-top: 10px;
}

.web .drawer-menu .nav-title {
  margin: 15px 0 5px calc(5px + var(--okx-drawer-padding));
}

.drawer-menu .nav-title {
  display: block;
  margin: 15px 0 5px 5px !important;
  color: var(--ion-color-primary-contrast);
}

.drawer-menu .nav-icon {
  height: 15px;
  width: 15px;
  margin: 0 5px 0px 0;
  padding: 5px;
}

.drawer-menu ion-footer {
  background-image: none;
  padding-top: 10px;
  padding-bottom: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -webkit-align-items: center;
          align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.drawer-menu ion-footer img {
  height: 20px;
}

.drawer-menu ion-footer .footer-link {
  --inner-border-width: 0;
}

.drawer-menu ion-footer::before {
  background-image: none;
}
@media screen {
/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/* @font-face {
	font-family: 'Karla-Regular';
	font-style: normal;
	font-weight: normal;
	src: url('../assets/fonts/karla/Karla-Regular.ttf');
} */

/* @font-face {
	font-family: 'Brown-Bold';
	font-style: normal;
	font-weight: normal;
	src: url('../assets/fonts/brown/BrownStd-Regular.otf');
}

@font-face {
	font-family: 'Brown-Regular';
	font-style: normal;
	font-weight: normal;
	src: url('../assets/fonts/brown/BrownStd-Regular.otf');
} */

@font-face {
	font-family: 'Josefin-Bold';
	font-style: normal;
	font-weight: normal;
	src: url(/static/media/JosefinSans-Bold.9ac2a608.ttf);
}

@font-face {
	font-family: 'Josefin-Regular';
	font-style: normal;
	font-weight: normal;
	src: url(/static/media/JosefinSans-Medium.f03e35ce.ttf);
}

/* @font-face {
	font-family: 'Karla-Bold';
	font-style: normal;
	font-weight: bold;
	src: url('../assets/fonts/karla/Karla-Bold.ttf');
} */

/* @font-face {
	font-family: 'PlayfairDisplay-Regular';
	font-style: normal;
	font-weight: normal;
	src: url('../assets/fonts/playfair-display/PlayfairDisplay-Regular.otf');
} */

/* @font-face {
	font-family: 'PlayfairDisplay-Bold';
	font-style: italic;
	font-weight: bold;
	src: url('../assets/fonts/playfair-display/PlayfairDisplay-Bold.otf');
} */

/** Ionic CSS Variables **/
:root {
	/** primary **/
	/* --ion-color-primary: #000000;
  --ion-color-primary-rgb: 0,0,0;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #000000;
	--ion-color-primary-tint: #1a1a1a; */
	--ion-color-primary: #1a1824;
  --ion-color-primary-rgb: 26,24,36;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #171520;
  --ion-color-primary-tint: #312f3a;

	/** secondary **/
	/* --ion-color-secondary: #aae3b2;
  --ion-color-secondary-rgb: 170,227,178;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #96c89d;
	--ion-color-secondary-tint: #b3e6ba; */
	--ion-color-secondary: #f26322;
  --ion-color-secondary-rgb: 242,99,34;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #d5571e;
  --ion-color-secondary-tint: #f37338;

	/** tertiary **/
	--ion-color-tertiary: #188339;
  --ion-color-tertiary-rgb: 24,131,57;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #157332;
  --ion-color-tertiary-tint: #2f8f4d;

	/** success **/
	--ion-color-success: #10dc60;
	--ion-color-success-rgb: 16, 220, 96;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #0ec254;
	--ion-color-success-tint: #28e070;

	/** warning **/
	--ion-color-warning: #ffce00;
	--ion-color-warning-rgb: 255, 206, 0;
	--ion-color-warning-contrast: #ffffff;
	--ion-color-warning-contrast-rgb: 255, 255, 255;
	--ion-color-warning-shade: #e0b500;
	--ion-color-warning-tint: #ffd31a;

	/** danger **/
	--ion-color-danger: #f04141;
	--ion-color-danger-rgb: 245, 61, 61;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #d33939;
	--ion-color-danger-tint: #f25454;

	/** dark **/
	--ion-color-dark: #222;
	--ion-color-dark-rgb: 34, 34, 34;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #111;
	--ion-color-dark-tint: #444;
	
	/** gray **/
	--ion-color-gray: #9b9b9b;
	--ion-color-gray-rgb: 155, 155, 155;
	--ion-color-gray-contrast: #ffffff;
	--ion-color-gray-contrast-rgb: 255, 255, 255;
	--ion-color-gray-shade: #858585;
	--ion-color-gray-tint: #a1a1a1;
	
	/** greenlight **/
		--ion-color-greenlight: #C1D5AE;
  	--ion-color-greenlight-rgb: 193,213,174;
  	--ion-color-greenlight-contrast: #000000;
  	--ion-color-greenlight-contrast-rgb: 0,0,0;
  	--ion-color-greenlight-shade: #aabb99;
		--ion-color-greenlight-tint: #c7d9b6;
		
	/** light **/
	--ion-color-light: #ebebeb;
	--ion-color-light-rgb: 235, 235, 235;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d4d4d4;
	--ion-color-light-tint: #f1f1f1;

	/** superlight **/
	--ion-color-superlight: #f4f4f4;
	--ion-color-superlight-rgb: 244, 244, 244;
	--ion-color-superlight-contrast: #000000;
	--ion-color-superlight-contrast-rgb: 0, 0, 0;
	--ion-color-superlight-shade: #ebebeb;
	--ion-color-superlight-tint: #f9f9f9;

	
	/** white **/
	--ion-color-white: #fff;
	--ion-color-white-rgb: 255, 255, 255;
	--ion-color-white-contrast: #222;
	--ion-color-white-contrast-rgb: 34, 34, 34;
	--ion-color-white-shade: #d7d8da;
	--ion-color-white-tint: #f5f6f9;
	
	/** black **/
	--ion-color-black: #111;
	--ion-color-black-rgb: 17, 17, 17;
	--ion-color-black-contrast: #eee;
	--ion-color-black-contrast-rgb: 238, 238, 238;
	--ion-color-black-shade: #000;
	--ion-color-black-tint: #555;
	
	/** form **/
	--ion-color-form-label: #b0abab;
	--ion-color-form-label-dark: #1e191a;
	--ion-color-note: #cbc6c6;
	
	/** custom values **/
	--ion-color-title2: #1073d0;
	--ion-color-box-holder: transparent;
	--ion-separator-color: #e6e6e6;
	--ion-feedback-label: #727272;
	--ion-top-small: 5px;
	--ion-top-medium: 30px;
	--ion-feedback-star: #9ba1a5;
	
	/** original theme file colors **/
	--ion-headings-font-weight: 300;
	
	--ion-color-angular: #ac282b;
	--ion-color-communication: #8e8d93;
	--ion-color-tooling: #fe4c52;
	--ion-color-services: #fd8b2d;
	--ion-color-design: #fed035;
	--ion-color-workshop: #69bb7b;
	--ion-color-food: #3bc7c4;
	--ion-color-documentation: #b16be3;
	--ion-color-navigation: #6600cc;
	
	--ion-padding: 25px;
	--ion-toolbar-min-height: 48px;
	
	--ion-card-radius: 20px;
	--ion-item-color: #999;
	--ion-item-background: transparent;
	--ion-text-color: var(--ion-color-primary);
	--ion-circle-color: rgb(232,231,233);
	
	/** fonts **/
	--ion-font-family: 'Josefin-Regular';
	--ion-default-font: 'Josefin-Regular';
	--ion-default-font-bold: 'Josefin-Bold';
	--ion-font-size: 13px;

	/* okx vars */
	--okx-drawer-max-width: 280px;
	--okx-small-drawer-max-width: 90px;
	--okx-drawer-width: var(--okx-drawer-max-width);
	--okx-sidebar-width: 310px;
	--okx-flex-min: 5px;
	--okx-flex-spacer: 20px;
	--okx-big-label-font-size: 42px;
	--okx-big-label-color: var(--ion-color-primary);
	--okx-big-label-margin: 2px 0;
	--okx-big-label-padding: 0;
	--okx-title-font-size: 20px;
	--okx-title-color: var(--ion-color-primary);
	--okx-title-margin: 5px;
	--okx-title-padding: 0;
	--okx-subtitle-font-size: 16px;
	--okx-subtitle-color: var(--ion-color-primary);
	--okx-subtitle-margin: 25px 0;
	--okx-subtitle-padding: 0;
	--okx-sectiontitle-font-size: 14px;
	--okx-sectiontitle-color: var(--ion-color-primary);
	--okx-sectiontitle-margin: 20px 0;
	--okx-sectiontitle-padding: 0;
	--okx-font-size: var(--ion-font-size);
	--okx-small-text-font-size: 11px;
	--okx-note-font-size: 12px;
	--okx-error-font-size: 11px;
	--okx-error-margin: 0 0 10px;
	--okx-hr-margin: 20px auto;
	--okx-border-color: var(--ion-item-border-color,var(--ion-border-color,var(--ion-color-step-150,rgba(0,0,0,0.13))));
	--okx-drawer-padding: 32px;
	--okx-box-radius: 10px;
	--okx-box-padding-left: 15px;
	--okx-box-padding-top: 15px;
	--okx-box-padding-right: 15px;
	--okx-box-padding-bottom: 15px;
	--okx-small-radius: 5px;
	--okx-normal-text-color: var(--ion-text-color);
	--okx-light-text-color: var(--ion-color-gray);
	--okx-circle-color: var(--ion-circle-color);
	--okx-incrementer-button-width: 40px;
	--okx-loyalty-badge-width: 40px;
	--okx-loyalty-badge-inner-padding: 4px;
	--okx-loyalty-bar-height: 50px;
	--okx-color-border-dash:#2D2B2B; 
	--okx-cancel-button-color: rgba(255, 255, 255, .2);
	--okx-scrollbar-handle-color: var(--ion-color-secondary);
	--okx-scrollbar-track-color: var(--ion-color-light);
	--okx-scrollbar-color: var(--okx-scrollbar-handle-color) var(--okx-scrollbar-track-color);
	--okx-color-black: #000;
	--okx-background-color:#fafafa;
}

.web {
	--ion-item-border-color: #000;
	--okx-bsmodal-top: 100px;
	--okx-bsmodal-width-lg: 500px;
	--okx-bsmodal-width-md: 420px;
	--okx-bsmodal-width-sm: 390px;
}
}
.ion-color-favorite {
	--ion-color-base: #69bb7b;
	--ion-color-base-rgb: 105, 187, 123;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-twitter {
	--ion-color-base: #1da1f4;
	--ion-color-base-rgb: 29, 161, 244;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: #1a8ed7;
	--ion-color-tint: #34aaf5;
}

.ion-color-google {
	--ion-color-base: #dc4a38;
	--ion-color-base-rgb: 220, 74, 56;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: #c24131;
	--ion-color-tint: #e05c4c;
}

.ion-color-vimeo {
	--ion-color-base: #23b6ea;
	--ion-color-base-rgb: 35, 182, 234;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: #1fa0ce;
	--ion-color-tint: #39bdec;
}

.ion-color-facebook {
	--ion-color-base: #3b5998;
	--ion-color-base-rgb: 59, 89, 152;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: #344e86;
	--ion-color-tint: #4f6aa2;
}

.ion-color-primary-shade {
	--ion-color-base: var(--ion-color-primary-shade);
	--ion-color-base-rgb: 185, 3, 11;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: var(--ion-color-primary-shade);
	--ion-color-tint: var(--ion-color-primary);
}

.ion-color-white {
	--ion-color-base: var(--ion-color-white);
	--ion-color-base-rgb: var(--ion-color-white-rgb);
	--ion-color-contrast: var(--ion-color-white-contrast);
	--ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
	--ion-color-shade: var(--ion-color-white-shade);
	--ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-black {
	--ion-color-base: var(--ion-color-black);
	--ion-color-base-rgb: var(--ion-color-black-rgb);
	--ion-color-contrast: var(--ion-color-black-contrast);
	--ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
	--ion-color-shade: var(--ion-color-black-shade);
	--ion-color-tint: var(--ion-color-black-tint);
}

.ion-color-gray,
.ion-color-grey {
	--ion-color-base: var(--ion-color-gray);
	--ion-color-base-rgb: var(--ion-color-gray-rgb);
	--ion-color-contrast: var(--ion-color-gray-contrast);
	--ion-color-contrast-rgb: var(--ion-color-gray-contrast-rgb);
	--ion-color-shade: var(--ion-color-gray-shade);
	--ion-color-tint: var(--ion-color-gray-tint);
}

.ion-color-superlight {
	--ion-color-base: var(--ion-color-superlight);
	--ion-color-base-rgb: var(--ion-color-superlight-rgb);
	--ion-color-contrast: var(--ion-color-superlight-contrast);
	--ion-color-contrast-rgb: var(--ion-color-superlight-contrast-rgb);
	--ion-color-shade: var(--ion-color-superlight-shade);
	--ion-color-tint: var(--ion-color-superlight-tint);
}

.ion-color-success {
	--ion-color-base: var(--ion-color-success);
	--ion-color-base-rgb: var(--ion-color-success-rgb);
	--ion-color-contrast: var(--ion-color-success-contrast);
	--ion-color-contrast-rgb: var(--ion-color-success-contrast-rgb);
	--ion-color-shade: var(--ion-color-success-shade);
	--ion-color-tint: var(--ion-color-success-tint);
}

.ion-color-warning {
	--ion-color-base: var(--ion-color-warning);
	--ion-color-base-rgb: var(--ion-color-warning-rgb);
	--ion-color-contrast: var(--ion-color-warning-contrast);
	--ion-color-contrast-rgb: var(--ion-color-warning-contrast-rgb);
	--ion-color-shade: var(--ion-color-warning-shade);
	--ion-color-tint: var(--ion-color-warning-tint);
}

.ion-color-danger {
	--ion-color-base: var(--ion-color-danger);
	--ion-color-base-rgb: var(--ion-color-danger-rgb);
	--ion-color-contrast: var(--ion-color-danger-contrast);
	--ion-color-contrast-rgb: var(--ion-color-danger-contrast-rgb);
	--ion-color-shade: var(--ion-color-danger-shade);
	--ion-color-tint: var(--ion-color-danger-tint);
}

/* Shared Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the CSS variables found in Ionic's source files.
// To view all the possible Ionic variables, see:
// https://beta.ionicframework.com/docs/theming/css-variables#ionic-variables
*/

/* ion reset */

.list-ios {
	margin-bottom: 0;
}

ion-modal ion-page,
ion-modal .ion-page {
	min-width: 100px;
}

/* end ion reset */

h1 {
	display: block;
	color: lightgray;
	position: relative;
	text-decoration: underline;
	-webkit-text-decoration-color: red;
	        text-decoration-color: red;
}

h1::after {
	display: block;
	content: 'DON\'T USE H1 TAG ! ! !';
	color: red;
}

h2,
h3,
h4,
h5,
h6 {
	display: block;
	margin: 0;
}

p,
div,
li,
ion-item,
.strong-text,
.normal-text {
	font-size: var(--ion-font-size);
}

p,
div,
li,
.strong-text,
.normal-text {
	color: var(--okx-normal-text-color);
}

.strong-text {
	font-weight: bold;
}

.normal-text {
	font-weight: normal;
}

.small-text {
	font-size: var(--okx-small-text-font-size);
	color: inherit;
	font-weight: 500;
}

.light-text {
	color: var(--okx-light-text-color);
}

/* ion-input, input {
	direction: ltr !important;
	unicode-bidi: bidi-override !important;
} */

input::-webkit-input-placeholder {
	color: var(--ion-color-primary) !important;
}

input:-ms-input-placeholder {
	color: var(--ion-color-primary) !important;
}

input::placeholder,
ion-input {
	color: var(--ion-color-primary) !important;
}

ion-input,
ion-select,
ion-datetime,
.sc-ion-input-md-h,
.sc-ion-input-ios-h,
.datetime-text,
.masked-input {
	--color: var(--ion-color-dark);
	color: var(--ion-color-dark);
	--placeholder-color: var(--ion-color-gray);
	font-family: var(--ion-default-font-bold)
}

ion-datetime.datetime-placeholder {
	opacity: 0.33;
}

.big-label {
	font-size: var(--okx-big-label-font-size);
	font-weight: bold;
	color: var(--okx-big-label-color);
	padding: var(--okx-big-label-padding);
	margin: var(--okx-big-label-margin);
}

.title {
	position: relative;
	font-family: var(--ion-default-font-bold);
	font-size: var(--okx-title-font-size);
	font-weight: bold;
	color: var(--okx-title-color);
	padding: var(--okx-title-padding);
	margin-bottom: 8px;
	text-transform: uppercase;
	/* letter-spacing: .05em; */
	z-index: 1;
}
.title.web-only{
	margin-bottom: 10px;
}

.subtitle,
h2,
h3,
h4,
h5,
h6 {
	font-size: var(--okx-subtitle-font-size);
	font-weight: bold;
	color: var(--okx-subtitle-color);
	padding: var(--okx-subtitle-padding);
	margin: var(--okx-subtitle-margin);
}

ion-label h2,
ion-label h3,
ion-label h4,
ion-label h5,
ion-label h6 {
	font-size: var(--okx-sectiontitle-font-size) !important;
}

.sectiontitle {
	font-size: var(--okx-sectiontitle-font-size);
	font-weight: bold;
	color: var(--okx-sectiontitle-color);
	padding: var(--okx-sectiontitle-padding);
	margin: var(--okx-sectiontitle-margin);
}

.title:first-child,
.subtitle:first-child,
.sectiontitle:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
	padding-top: 0;
	margin-top: 0;
}

.title2 {
	color: var(--ion-color-title2);
	margin: 40px 0 20px;
}

.padded {
	padding: var(--ion-padding);
}

.no-margin {
	margin: 0;
}

.no-margin-bottom {
	margin-bottom: 0;
}

.no-margin-top {
	margin-top: 0;
}

.no-padding-top {
	padding-top: 0;
}

.no-padding-bottom {
	padding-bottom: 0;
}

.no-padding {
	padding: 0;
}

ion-grid.no-padding {
	--ion-grid-padding: 0;
}

ion-grid.no-padding ion-col {
	padding: 0;
}

.md {
	--ion-toolbar-background: var(--ion-color-primary-contrast);
	--ion-toolbar-color: #fff;
	--ion-toolbar-color-activated: #fff;
}

.box-holder {
	background-color: var(--ion-color-box-holder);
	border-radius: 5px;
	padding: 0px 15px;
	border: 1px solid var(--ion-color-light);
}

.box-holder.box-holder--feedback {
	border: 1px solid rgba(var(--ion-color-gray-rgb), .7);
}

.pad10 {
	padding: 10px;
}

.pad20 {
	padding: 20px;
}

.pad40b {
	padding-bottom: 40px;
}

.pad5l {
	padding-left: 5px;
}

.pad17l {
	padding-left: 17px;
}

.box-holder ion-item {
	--padding-start: 0px;
	--border-color: var(--ion-separator-color);
}

.box-holder.box-holder--feedback ion-item {
	--border-color: 1px solid rgba(var(--ion-color-gray-rgb), .7);
}

.single-item {
	margin-bottom: 10px;
}

ion-label h2 {
	color: var(--ion-color-form-label-dark);
	font-weight: 500;
}

ion-note {
	color: var(--ion-color-note);
	font-size: var(--okx-note-font-size);
}

ion-label:not(.contact-number-label).ion-text-wrap {
	line-height: 1 !important;
}

ion-item {
	--padding-start: 0;
	--inner-padding-end: 0;
}

ion-item ion-button {
	margin-bottom: 0;
	height: auto;
}

ion-button {
	--background-activated: var(--ion-color-light);
}

ion-button.rounded {
	--border-radius: 60px;
}

.float-input-holder {
	--padding-start: 0px;
	--border-color: var(--ion-color-form-label)
}

.top-small {
	margin-top: var(--ion-top-small);
}

.top-medium {
	margin-top: var(--ion-top-medium);
}

.verified-content {
	width: 190px;
	margin: 0 auto;
	text-align: center;
}

ion-button {
	height: 44px;
	--border-radius: var(--okx-box-radius);
	--box-shadow: none;
	font-size: var(--ion-font-size);
	margin-bottom: 10px;
	text-transform: none;
	letter-spacing: normal;
}

ion-button.validation {
	--background: var(--okx-color-white);
	--color: var(--ion-color-primary);
	--border-width: 1px;
	--border-style: solid;
	--border-color: var(--ion-color-secondary);
}

ion-button.ion-color-primary {
	--border-style: solid;
	--border-width: 2px;
	--border-color: var(--ion-color-primary);
}

ion-button.ion-color-secondary {
	/* --border-style: solid;
	--border-width: 2px;
	--border-color: var(--ion-color-primary); */
	--ion-color-contrast: #fff !important;
	--background-activated: var(--ion-color-light);
	font-family: var(--ion-font-family);
	font-weight: normal;
	text-transform: uppercase;
}

ion-button.ion-color-secondary.button-disabled {
	--ion-color-base: rgba(var(--ion-color-gray-rgb), .5) !important;
	--color: rgba(var(--ion-color-gray), 1);
}

ion-button.ion-color-white {
	--border-style: none;
	--border-width: 0;
	--border-color: transparent;
}


ion-button.cancel {
	height: 44px;
	--background: var(--okx-cancel-button-color) !important;
	--color: var(--ion-color-primary);
}

ion-button.cancel.button-fill {
	--background: rgba(var(--ion-color-gray-rgb), .2) !important;
}

ion-button.button-outline {
	height: 44px;
	--background-activated: var(--ion-color-light);
	font-family: var(--ion-default-font-bold);
	font-weight: normal;
	text-transform: uppercase;
}

ion-button.link {
	--background: var(--okx-cancel-button-color) !important;
	--color: inherit;
	font-weight: 500;
	text-transform: none;
	text-transform: initial;
	cursor: pointer;
}

ion-button.link.underlined.ion-color-secondary {
	font-family: var(--ion-font-family);
	font-size: var(--okx-small-text-font-size);
	font-weight: normal;
}

ion-button[type="submit"] {
	height: 44px;
	--background: var(--ion-color-secondary);
	/* --color: var(--ion-color-contrast-secondary); */
	text-transform: uppercase;
}

ion-header ion-title {
	text-transform: uppercase;
	font-weight: bold;
}

.pov,
.poo,
.relative {
	position: relative;
}

.mixed-right,
.mixed-right * {
	text-align: right;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-align-self: center;
	        align-self: center;
	justify-self: flex-end;
}

.mixed-left,
.mixed-left * {
	text-align: left;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-align-self: center;
	        align-self: center;
	justify-self: flex-start;
}

.mixed-left,
.mixed-left * .grow {
	-webkit-flex-grow: 0.5;
	        flex-grow: 0.5;
}

.contrast-separator {
	display: block;
	height: 0;
	line-height: 0;
	border: 1px solid rgba(255, 255, 255, 0.3);
	margin: 16px 0;
}

.contrast-separator.contrast-separator-mini {
	margin: 12px 0;
}

.poster {
	padding-top: 65%;
	background-color: transparent;
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.no-padding {
	padding: 0;
}

.text-link {
	--ion-toolbar-background: transparent;
	--background-hover: transparent;
	--background-activated: transparent;
	--background-focused: transparent;
	--color: var(--ion-color-gray);
	--color-hover: var(--ion-color-dark);
	--color-activated: var(--ion-color-dark);
	--color-focused: var(--ion-color-dark);
	--ripple-color: #fff;
	text-transform: unset;
	font-size: inherit;
}

span.link {
	color: inherit;
	cursor: pointer;
	font-weight: 500;
}

span.link.link-primary {
	color: var(--ion-color-primary);
	cursor: pointer;
}

.paddLR {
	padding-right: 0px;
	padding-left: 0px;
}

ion-button.link,
ion-buttons ion-button.link,
.sc-ion-buttons-md-h.ion-color.sc-ion-buttons-md-s .button.link,
.ion-color .sc-ion-buttons-md-h.sc-ion-buttons-md-s .button.link,
.sc-ion-buttons-ios-h.ion-color.sc-ion-buttons-ios-s .button.link,
.ion-color .sc-ion-buttons-ios-h.sc-ion-buttons-ios-s .button.link {
	--padding-start: 0;
	--padding-end: 0;
	--padding-top: 0;
	--padding-bottom: 0;
	--background: transparent;
	--background-active: transparent;
	--background-focused: transparent;
	--background-hover: transparent;
	--border-color: transparent;
	--border-radius: 0;
	--border-style: none;
	--border-width: 0;
	--box-shadow: none;
	--ripple-color: transparent;
	--color: var(--ion-color-primary);
	--color-hover: var(--ion-color-primary-shade);
	--color-focused: var(--ion-color-primary-shade);
	--color-activated: var(--ion-color-primary-shade);
	font-size: inherit;
}

.segment-holder {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
	overflow: hidden;
}

.segment-holder ion-segment {
	-webkit-flex: 0 0 auto;
	        flex: 0 0 auto;
}

.segment-holder .segment-content {
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
	overflow: hidden;
	overflow-y: auto;
}

.scrollable {
	overflow: auto;
}

.scrollable-y {
	overflow: hidden;
	overflow-y: auto;
}

.scrollable-x {
	overflow: hidden;
	overflow-x: auto;
}

.bordered,
.bordered-top,
.bordered-bottom,
.bordered-left,
.bordered-right {
	border: 1px solid var(--okx-border-color);
}

.bordered-primary {
	border: 1px solid var(--ion-color-primary);
	/* border: 1px solid var(--ion-color-secondary); */
}

.bordered-top {
	border-width: 1px 0 0;
}

.bordered-bottom {
	border-width: 0 0 1px;
}

.bordered-left {
	border-width: 0 0 0 1px;
}

.bordered-right {
	border-width: 0 1px 0 0;
}

.underlined {
	text-decoration: underline;
}

ion-segment-button {
	height: 50px;
	border-radius: 0px;
	color: var(--ion-color-dark);
	border: 0 none;
	border-bottom: 2px solid var(--ion-color-primary-contrast);
	background-color: var(--ion-color-primary-contrast);
	font-weight: bold;
}

ion-segment-button.segment-button-checked {
	border-bottom-color: var(--ion-color-secondary);
	color: var(--ion-color-primary);
	background-color: var(--okx-background-color);
}
ion-segment-button.segment-button-has-label-only {
	background-color: var(--okx-background-color);
}

.clickable {
	cursor: pointer;
}

.field-error {
	color: var(--ion-color-danger);
	font-size: var(--okx-error-font-size);
	margin: var(--okx-error-margin);
}

ion-checkbox {
	--ion-color-secondary-contrast: #ffffff;
	--border-radius: 50%;
}

.absolute-wrapper {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
}

.flex-col-wrapper,
.flex-col-holder,
.flex-row-wrapper,
.flex-row-holder {
	display: -webkit-flex;
	display: flex;
}

.flex-col-wrapper,
.flex-col-holder {
	-webkit-flex-direction: row;
	        flex-direction: row;
}

.flex-col-wrapper.flex-reverse,
.flex-col-holder.flex-reverse {
	-webkit-flex-direction: row-reverse;
	        flex-direction: row-reverse;
}

.flex-row-wrapper,
.flex-row-holder {
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.flex-row-wrapper.flex-reverse,
.flex-row-holder.flex-reverse {
	-webkit-flex-direction: column-reverse;
	        flex-direction: column-reverse;
}

.flex-col-wrapper>div,
.flex-col-holder>div,
.flex-row-wrapper>div,
.flex-row-holder>div {
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
}

.flex-col-wrapper>div.flex-min,
.flex-col-holder>div.flex-min,
.flex-row-wrapper>div.flex-min,
.flex-row-holder>div.flex-min {
	-webkit-flex: 0 1 var(--okx-flex-min);
	        flex: 0 1 var(--okx-flex-min);
}

.flex-col-wrapper>div.flex-optional,
.flex-col-holder>div.flex-optional,
.flex-row-wrapper>div.flex-optional,
.flex-row-holder>div.flex-optional {
	-webkit-flex: 1 1;
	        flex: 1 1;
}

.flex-col-wrapper>div.flex-spacer,
.flex-col-holder>div.flex-spacer,
.flex-row-wrapper>div.flex-spacer,
.flex-row-holder>div.flex-spacer {
	-webkit-flex: 0 1 var(--okx-flex-spacer);
	        flex: 0 1 var(--okx-flex-spacer);
}

.flex-centered {
	-webkit-align-items: center;
	        align-items: center;
}

.flex-stretched {
	-webkit-align-items: stretch;
	        align-items: stretch;
}

.absolute-content {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	-webkit-padding-start: 16px;
	        padding-inline-start: 16px;
	-webkit-padding-start: var(--ion-padding, 16px);
	        padding-inline-start: var(--ion-padding, 16px);
	-webkit-padding-end: 16px;
	        padding-inline-end: 16px;
	-webkit-padding-end: var(--ion-padding, 16px);
	        padding-inline-end: var(--ion-padding, 16px);
	padding-left: 16px;
	padding-left: var(--ion-padding, 16px);
	padding-right: 16px;
	padding-right: var(--ion-padding, 16px);
	padding-top: 16px;
	padding-top: var(--ion-padding, 16px);
	padding-bottom: 16px;
	padding-bottom: var(--ion-padding, 16px);
}

.absolute-content>div {
	position: relative;
	z-index: 1;
}

.centered,
.centered * {
	text-align: center;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-align-self: center;
	        align-self: center;
	justify-self: center;
}

.righted,
.righted * {
	text-align: right;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	-webkit-align-items: flex-end;
	        align-items: flex-end;
	-webkit-align-self: flex-end;
	        align-self: flex-end;
	justify-self: flex-end;
}

.righted,
.righted * .grow {
	-webkit-flex-grow: 0;
	        flex-grow: 0;
}

.righted,
.righted * .instruction-grow {
	-webkit-flex-grow: 1;
	        flex-grow: 1;
}

.lefted,
.lefted * {
	text-align: left;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	-webkit-align-self: flex-start;
	        align-self: flex-start;
	justify-self: flex-start;
}

.self-center {
	-webkit-align-self: center;
	        align-self: center;
}

.ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.uppercase {
	text-transform: uppercase;
}

.capitalize {
	text-transform: capitalize;
}

.bold {
	font-weight: bold;
}

.hidden,
.web-only {
	display: none;
}

.invisible {
	visibility: hidden;
}

hr {
	color: transparent;
	background-color: transparent;
	height: 0;
	min-height: 0;
	line-height: 0;
	margin: var(--okx-hr-margin);
	border: 0 none;
	border-top: 2px solid var(--ion-color-gray);
}

ion-badge.small {
	width: 15px;
	height: 15px;
	line-height: 13px;
	font-size: 7px;
	--padding-start: 2px;
	--padding-end: 2px;
	--padding-top: 2px;
	--padding-bottom: 2px;
	color: var(--ion-color-white) !important;
	border-radius: 50%;
	min-width: 11px;
	text-transform: none;
	margin-left: 5px;
	position: absolute;
}

.card-thumb {
	display: inline-block;
	width: 55px;
	height: 35px;
	/*background-color: var(--ion-color-light);*/
	position: relative;
	border-radius: 3px;
	border: 1px solid var(--ion-color-light);
}

.card-thumb.visa::after {
	display: block;
	position: absolute;
	left: 0;
	top: 10px;
	right: 0;
	bottom: 0;
	content: 'VISA';
	color: #2566af;
	text-align: center;
	font-weight: 900;
	font-style: italic;
}

.card-thumb.mc::before {
	display: block;
	position: absolute;
	left: 5px;
	top: 5px;
	;
	width: 26px;
	height: 26px;
	content: '';
	text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
	border-radius: 50%;
	background-color: #c00;
}

.card-thumb.mc::after {
	display: block;
	position: absolute;
	left: 0;
	top: 2px;
	right: 0;
	bottom: 0;
	content: 'MasterCard';
	color: #fff;
	font-size: 8px;
	padding-top: 11px;
	text-align: center;
	font-weight: bold;
	font-style: italic;
	text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
	/*background-image: radial-gradient(circle at 36px 16px, #f90 0px 12px,transparent 13px);*/
	background-image: radial-gradient(circle at 36px 16px, rgba(255, 153, 0, 0.7) 0px 12px, transparent 13px);
}

input:-webkit-autofill,
textarea:-webkit-autofill,
input:-webkit-autofill:hover,
textarea:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill:active {
	-webkit-transition: background-color 5000s ease-in-out 0s;
	transition: background-color 5000s ease-in-out 0s;
}

.order-status {
	width: auto;
	display: inline-block;
	font-family: var(--ion-default-font);
	font-size: var(--okx-small-text-font-size);
	font-weight: bold;
	text-transform: uppercase;
	border: 1px solid var(--ion-color-primary);
	padding: 2px 8px;
	line-height: 1;
}

/* Specific style for heading / text immidietely underneath title of the screen */
.heading {
	font-size: 12px;
	font-weight: normal;
	letter-spacing: .021em;
}

/* Specific style, text seems like input-label & input are inline */
.inline-input {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
}

.inline-input--bordered {
	border-bottom: 1px solid var(--ion-color-light);
}

.blocked-input {
	width: 100%;
	display: block;
}

.inline-input>ion-text,
.inline-input>label {
	-webkit-align-self: center;
	        align-self: center;
	-webkit-flex: 0 0 40%;
	        flex: 0 0 40%;
}

.inline-input>ion-text>span,
.blocked-input>ion-text>span {
	font-family: var(--ion-default-font-bold);
	font-weight: bold;
	letter-spacing: .044em;
	color: var(--ion-color-primary);
}

ion-input:-webkit-autofill,
input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 50px white inset;
	/* Change the color to your own background color */
	-webkit-text-fill-color: #999;
}

ion-input:-webkit-autofill,
input:-webkit-autofill:focus {
	-webkit-box-shadow: transprent, 0 0 0 50px white inset;
	-webkit-text-fill-color: #999;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
input:-webkit-autofill:hover,
textarea:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill:active {
	-webkit-transition: background-color 5000s ease-in-out 0s;
	transition: background-color 5000s ease-in-out 0s;
}

/* Specific style, text seems like input-label & input are inline */
.inline-input {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
}

.inline-input--bordered {
	border-bottom: 1px solid var(--ion-color-light);
}

.blocked-input {
	width: 100%;
	display: block;
}

.blocked-input--bordered {
	border-bottom: 1px solid rgba(var(--ion-color-light-rgb), 1);
}

.inline-input>ion-text,
.inline-input>label {
	-webkit-align-self: center;
	        align-self: center;
	-webkit-flex: 0 0 40%;
	        flex: 0 0 40%;
}

.inline-input.inline-input--validate {
	-webkit-justify-content: initial;
	        justify-content: initial;
	border-bottom: 0;
}

.inline-input.inline-input--validate .data-picker-input {
	width: 100%;
	background-color: transparent;
}

.inline-input>ion-text>span,
.blocked-input>ion-text>span {
	color: var(--ion-color-primary);
	font-size: var(--okx-default-font-size);
}

.inline-input>ion-input,
.blocked-input>ion-input {
	--placeholder-color: var(--ion-color-gray);
	--placeholder-opacity: 1;
	text-transform: none;
	text-transform: initial;
	/* margin-inline-start: 20px; */
}

.blocked-input>ion-input {
	-webkit-margin-start: 0;
	        margin-inline-start: 0;
}

.inline-input.inline-input-gaped>ion-input {
	-webkit-margin-start: 50px;
	        margin-inline-start: 50px;
}

.input-field-wrapper.item-has-focus {
	border: 1px solid var(--ion-color-secondary) !important;
}

.input-field-wrapper.dropdown-field {
	position: relative;
}

.input-field-wrapper.dropdown-field::after {
	content: url(/static/media/caret-down-black.43a37235.svg);
	height: 20px;
	width: 20px;
	position: absolute;
	right: 10px;
	top: 0;
	bottom: 0;
	margin: auto;
}

.input-field-wrapper,
.web .input-field-wrapper {
	padding: 0 20px;
	background: var(--ion-color-white);
	border-radius: var(--okx-box-radius);
	border: 1px solid var(--ion-color-light);
	margin: 0 0.5px;
}

.input-field-wrapper input,
.input-field-wrapper ion-input {
	height: 100%;
}

.input-field-wrapper ion-textarea {
	margin: 0;
	-webkit-padding-start: 0;
	        padding-inline-start: 0;
	--padding-start: 0
}

.input-field-container {
	margin-bottom: 10px;

}

.input-field-container .normal-text {
	margin-bottom: 3px;
	display: block;
	text-align: start;
	font-family: var(--ion-default-font-bold);
	color:var(--ion-color-primary);
}

.default-padding {
	padding: 0 var(--ion-padding);
}

.box-wrapper{
  padding: 10px 15px;
  background-color: var(--ion-color-white);
  border-radius: var(--okx-box-radius);
  border: 1px solid var(--ion-color-light);
}

.block {
	display: block;
}
.web * { 
	scrollbar-width: thin !important;
	scrollbar-color: var(--okx-scrollbar-color);
}

.web ::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

.web ::-webkit-scrollbar-track {
	background: var(--okx-scrollbar-track-color);
	border-radius: 0;
}

.web ::-webkit-scrollbar-thumb {
	background: var(--okx-scrollbar-handle-color);
	border-radius: 0;
}

ion-app.ion-page.web {
	background-image: url(/static/media/dashboard-03.45aec6a4.jpg);
	background-repeat: no-repeat;
	background-position: 50% 0;
	background-size: cover;
}

.web .web-only {
	display: block;
}


/* ion-app.ion-page.web {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

ion-app.ion-page.web.route-dashboard,
ion-app.ion-page.web.route-loyalty,
ion-app.ion-page.web.route-order-completed,
ion-app.ion-page.web.route-social {
	background-image: url(../assets/images/new-dashboard-01.jpg);
}

ion-app.ion-page.web.route-history-details,
ion-app.ion-page.web.route-history,
ion-app.ion-page.web.route-login,
ion-app.ion-page.web.route-register, 
ion-app.ion-page.web.route-reset-password {
	background-image: url(../assets/images/history-bg-web.jpg);
} */

.web .static ion-toolbar.primary-toolbar {
	--background: transparent none;
}

.web .mbsc-select-input {
	cursor: pointer;
}

.web .mbsc-select-input[disabled], .web .mbsc-sel-hdn[disabled] + .mbsc-select-input {
	cursor: default;
}

.web .static.route-register + .dynamic-header {
	display: none;
}

.web #main > ion-content:not(.route-order):not(.route-dashboard):not(.route-delivery-options):not(.route-delivery-address-check):not(.route-locations):not(.route-refer-a-friend):not(.route-pick-up-point-check):not(.route-history) > .no-padding,
.web #main > ion-content > .ion-padding {
	position: fixed;
	left: 50%;
	top: var(--okx-bsmodal-top);
	bottom: 80px;
	width: var(--okx-bsmodal-width-lg);
	min-height: 240px;
	margin-left: calc(var(--okx-bsmodal-width-lg) / -2);
	background-color: var(--okx-background-color);
	border-radius: var(--okx-box-radius);
	box-shadow: 0 0 32px rgba(0,0,0,0.25);
}
/* 
.web .restaurant-card {
	top: 50%;
    bottom: auto;
    left: 50%;
    right: auto;
    position: fixed;
    transform: translate(-50%,-50%);
    width: 50%;
    max-width: var(--okx-bsmodal-width-lg);
    min-width: 280px;
    padding: 220px 64px 64px;
    margin-left: auto;
    margin-right: auto;
    border-radius: var(--okx-box-radius);
} */

/* .web #main > ion-content:not(.route-order) > .no-padding > .absolute-content > .flex-min:last-of-type > ion-button, */
/* .web #main > ion-content:not(.route-order) > .ion-padding > .absolute-content > .flex-min:last-of-type > ion-button, */
/* .web #main > ion-content:not(.route-order-summary) > .no-padding > .absolute-content > .flex-min:last-of-type > ion-button, */
/* .web #main > ion-content:not(.route-order-summary) > .ion-padding > .absolute-content > .flex-min:last-of-type > ion-button, */
.web .restaurant-card ion-card-content ion-button:last-of-type {
	margin: 0;
}

/*
.web #main > ion-header,
.web #main > ion-content {
	-webkit-animation: fadein 1s;
	-moz-animation: fadein 1s;
	-ms-animation: fadein 1s;
	-o-animation: fadein 1s;
	animation: fadein 1s;
}

@keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}
@-moz-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}
@-webkit-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}
@-ms-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}
@-o-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}
*/

/* responsive */


/* responsive end */

/* big screen override */

@media screen and (min-width: 992px) {
	.web ion-header:not(.route-login) + ion-toolbar.dynamic-header,
	.web ion-header:not(.route-login) + .content-spinner + ion-toolbar.dynamic-header {
		top: 16px;
    width: 100px;
	}
}

